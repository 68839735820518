import React from 'react';
import AppointmentBooking from './AppointmentBookingComponent';
import { Button, Modal } from 'react-bootstrap';
import Michelle from './MichelleComponent';

class Appointments extends React.Component {


    constructor(props) {
        super(props);

        this.toggleModal = this.toggleModal.bind(this);

        this.state = {
            isModalOpen: false
        };
    }

    toggleModal() {
        this.setState({
            isModalOpen: !this.state.isModalOpen
        });
    }


    componentDidMount() {
    }

    render() {
        return (
            <div>
                <div className="section">
                    <div className="container">
                        {!this.props.bookingTime ?
                            <div className="row justify-content-center align-items-center mb-4">
                                <div className="col-lg-7 text-center">
                                    <p>Available dates are shown in green. Click a green date to see available openings.</p>
                                    <p>All appointments are with <a href="/" onClick={(e) => { this.toggleModal(); e.preventDefault(); }}>Michelle</a>.</p>
                                </div>
                            </div> : <></>}

                        <div className="row d-flex align-items-center justify-content-center">
                            <div className="col-12 d-flex align-items-center justify-content-center text-center">
                                <AppointmentBooking bookingTime={this.props.bookingTime}></AppointmentBooking>
                            </div>
                        </div>
                    </div>
                </div>
                {!this.props.bookingTime ?
                    <div>
                        <div className="section sec-media bg-light">
                            <div className="container">
                                <div className="row g-5 justify-content-around">
                                    <div className="col-lg-4 align-self-center" data-aos="fade-up" data-aos-delay="0">
                                        <h2 className="heading">Book an appointment online</h2>


                                        <p><strong>
                                            Get eyelash extensions today to make your life easier tomorrow morning!

                                        </strong>
                                        </p>
                                        <p>
                                            If you are tired of the time it takes to get your eyes to pop and look awake, then eyelash extensions are for you. Lash extensions lengthen and curl your lashes and darken your lash line instantly.
                                        </p>
                                        <p>
                                            Lashes West is recognized as “Best Lash Salon” in the Minneapolis area and all of our services are custom made for your eye shape and individual taste.
                                        </p>
                                        <p>
                                            Flexible evening and weekend appointments are available. Lashes West specializes in eyelash extensions and only eyelash extensions. You will have the same lash artist every time whose passion is focused on making your eyes stand out.
                                        </p>



                                        <p><a href="/lash-services" className="btn btn-primary text-white">Our services</a></p>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="img-wrap">
                                            <img src="/assets/images/about_2.jpg" alt="about 2" className="img-fluid img-1" data-aos="fade-up" data-aos-delay="100"></img>
                                            <img src="/assets/images/about_3.jpg" alt="about 3" className="img-2" data-aos="fade-up" data-aos-delay="200"></img>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="section">
                            <div className="container">
                                <div className="row g-1 align-items-stretch">
                                    <div className="col-lg-4 order-1 order-lg-2" data-aos="fade-up">
                                        <div className="h-100"><div className="frame h-100"><div className="feature-img-bg h-100" style={{ backgroundImage: "url('/assets/images/about_5.jpg')" }}></div></div></div>
                                    </div>
                                    <div className="col-6 col-sm-6 col-lg-4 feature-1-wrap d-md-flex flex-md-column order-lg-1" >
                                        <div className="feature-1 d-md-flex custom-mb" data-aos="fade-up" data-aos-delay="100">
                                            <div className="align-self-center">
                                                <span className="icon-airline_seat_flat display-4 text-primary mb-3 d-block"></span>
                                                <h3 className="text-secondary">Quiet, private setting</h3>
                                                <p className="mb-0">Exclusive appointments in a private salon.</p>
                                            </div>
                                        </div>
                                        <div className="feature-1 " data-aos="fade-up" data-aos-delay="200">
                                            <div className="align-self-center">
                                                <span className="icon-shopping-bag display-4 text-primary mb-3 d-block"></span>
                                                <h3 className="text-secondary">Shop beauty products</h3>
                                                <p className="mb-0">Shop RevitaLash and other lash care and beauty products.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-6 col-sm-6 col-lg-4 feature-1-wrap d-md-flex flex-md-column order-lg-3" >

                                        <div className="feature-1 d-md-flex custom-mb" data-aos="fade-up" data-aos-delay="100">
                                            <div className="align-self-center">
                                                <span className="icon-loyalty display-4 text-primary mb-3 d-block"></span>
                                                <h3 className="text-secondary">Great customer loyalty program</h3>
                                                <p className="mb-0">Rewards for returning clients.</p>
                                            </div>
                                        </div>

                                        <div className="feature-1 d-md-flex" data-aos="fade-up" data-aos-delay="200">
                                            <div className="align-self-center">
                                                <span className="icon-payment display-4 text-primary mb-3 d-block"></span>
                                                <h3 className="text-secondary">Convenient payment options</h3>
                                                <p className="mb-0">Pay be credit card, Venmo, Apple Pay or cash.</p>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    : <></>}

                <Modal show={this.state.isModalOpen} onHide={this.toggleModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>About Michelle</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Michelle></Michelle>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="primary" className="text-white" onClick={this.toggleModal}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }
}

export default Appointments;