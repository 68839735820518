import React from 'react';

function Michelle(props) {


    return (
        <> <p>
            Michelle Petersen is the owner of Lashes West.  She has been an eyelash stylist since 2009 in the West Lake Minnetonka area.
            Michelle was trained for eyelash extension services in Chicago and Fort Lauderdale.
        </p>
            <img src="/assets/images/michelle.jpg" alt="michelle" className="img-fluid m-2"></img>
            <p>
                Michelle comes from an extensive cosmetic background, with 20 years of experience in application, management, training and sales.
                She has worked in these capacities for Estee Lauder, Lancôme and Laura Mercier Cosmetics.
            </p>
            <p>
                She also graduated from Faces Etc. of Minnesota, the only accredited school exclusively for professional makeup application in the state.
            </p>
            <p>
                Creativity and keen listening abilities enable her to customize each client’s wishes and needs with practicality so they can have the most beautiful eyes,
                with the least amount of work and upkeep. Michelle enjoys making her clients look great every day and amplifying the drama with long lashes for those extra special occasions.
            </p></>
    );
}

export default Michelle;








