import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { init, sendForm } from 'emailjs-com';
init("user_0Jxl9b6dGzX8IdZZG5yl5");

function Location(props) {

    const { register, handleSubmit, watch, formState: { errors } } = useForm();
    const message = watch('message') || "";
    var messageCharsLeft = 2000 - message.length;
    const [statusMessage, setStatusMessage] = useState("Message");

    const onSubmit = (data) => {
        const statusMessage = document.querySelector('.status-message');
        const form = document.querySelector('#contact-form');
        sendForm('service_cubdysv', 'template_dq98soe', '#contact-form')
            .then(function (response) {
                form.reset();
                setStatusMessage("Thank you, your message has been sent.");
                statusMessage.className = "status-message success";
                setTimeout(() => {
                    statusMessage.className = 'status-message'
                }, 10000)
            }, function (error) {
                setStatusMessage("Failed to send message! Please try again later.");
                statusMessage.className = "status-message failure";
                setTimeout(() => {
                    statusMessage.className = 'status-message'
                }, 10000)
            });
    }

    return (
        <div>
            <div className="section  bg-light">
                <div className="container">
                    <div className="row justify-content-center align-items-center">
                        <div className="col-lg-7 text-center">
                            <h1 className="heading mb-2" data-aos="fade-up">Location</h1>
                            <p className="w-75 mb-4 mx-auto bold" data-aos="fade-up" data-aos-delay="100">Please see our location information below.</p>
                            <p className="w-75 mb-4 mx-auto" data-aos="fade-up" data-aos-delay="100">You can also get in touch to book an appointment, or to find out more about lash services and products with these links:</p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-6 d-flex justify-content-end"><a href="#contact" className="btn btn-primary text-white" data-aos="fade-up" data-aos-delay="200">Contact</a></div>
                        <div className="col-6 d-flex justify-content-start"><a href="/#about-us" className="btn btn-primary text-white" data-aos="fade-up" data-aos-delay="200">About</a></div>
                    </div>

                </div>
            </div>

            <div id="location" className="section">
                <div className="container">
                    <div className="row justify-content-center mb-5">
                        <div className="col-lg-7 text-center" data-aos="fade-up" data-aos-delay="100">
                            <span className="subheading">Location</span>
                            <h3 className="heading mb-4">West Lake Minnetonka</h3>
                            <a href="https://goo.gl/maps/ovK6LuFCNUzGu7ch7" target="_blank" rel="noreferrer">
                                <p>
                                    Phenix Salon Suites<br />
                                    12993 Ridgedale Drive<br />
                                    Suite 107<br />
                                    Minnetonka, MN 55305
                                </p>
                                <div className="row">
                                    <div className="col-md-6">
                                        <img className="d-block d-md-none" src="/assets/images/location/ridgedale-small.jpg" alt="click for directions" data-no-retina></img>
                                        <img className="d-none d-md-block" src="/assets/images/location/ridgedale.jpg" alt="click for directions" data-no-retina></img>
                                    </div>
                                    <div className="col-md-6">
                                        <img className="d-block d-md-none" src="/assets/images/location/ridgedale-map-small.jpg" alt="click for directions" data-no-retina></img>
                                        <img className="d-none d-md-block" src="/assets/images/location/ridgedale-map.jpg" alt="click for directions" data-no-retina></img>
                                    </div>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            </div>

            <div id="contact" className="section" >
                <div className="container">
                    <form id='contact-form' onSubmit={handleSubmit(onSubmit)} className="row justify-content-between">

                        <div className="col-md-6 col-lg-5" data-aos="fade-up" data-aos-delay="200">
                            <div className="row">
                                <div className="col-6 col-lg-6 mb-4">
                                    <h3 className="h6 fw-bold text-secondary">Address</h3>
                                    <a href="https://goo.gl/maps/ovK6LuFCNUzGu7ch7" target="_blank" rel="noreferrer">
                                        <p>
                                            Phenix Salon Suites<br />
                                            12993 Ridgedale Drive<br />
                                            Suite 107<br />
                                            Minnetonka, MN 55305
                                        </p>
                                    </a>
                                </div>
                                <div className="col-6 col-lg-6 mb-4">
                                    <h3 className="h6 fw-bold text-secondary">Phone</h3>
                                    <p>
                                        612-327-7431
                                    </p>
                                </div>

                                <div className="col-6 col-lg-6 mb-4">
                                    <h3 className="h6 fw-bold text-secondary">Follow</h3>
                                    <ul className="list-unstyled social-custom">
                                        <li><a href="https://www.instagram.com/lasheswest/"><span className="icon-instagram"></span></a></li>
                                        <li><a href="https://www.facebook.com/Lashes-West-329016520551939/"><span className="icon-facebook"></span></a></li>
                                        <li><a href="https://www.linkedin.com/in/michelle-petersen-36672217/"><span className="icon-linkedin"></span></a></li>
                                        <li><a href="https://www.pinterest.com/1d5srur5d44ri1h/lashes-west/"><span className="icon-pinterest"></span></a></li>
                                    </ul>
                                </div>
                                <div className="col-6 col-lg-6 mb-4">
                                    <h3 className="h6 fw-bold text-secondary">Email</h3>
                                    <p>
                                        <a href="mailto: michelle@lasheswest.com">michelle@lasheswest.com</a>
                                    </p>
                                </div>

                            </div>
                        </div>
                        <div className="col-md-6 col-lg-6" data-aos="fade-up" data-aos-delay="100">
                            <div className="row">
                                <div className="col-6 col-lg-6 mb-4">
                                    <h3 className="h6 fw-bold text-secondary">Send a message</h3>
                                </div>
                            </div>
                            <div className="row">
                                <div className="mb-3 col-lg-6">
                                    {errors.userName && errors.userName.type === "required" && (
                                        <div role="alert">Name is required<br /></div>
                                    )}
                                    <label htmlFor="name" className="ps-3 fw-bold mb-2">Name</label>
                                    <input type="text" className="form-control" name="userName" aria-invalid={errors.userName ? "true" : "false"}
                                        {...register("userName", { required: true })} maxLength='30'></input>
                                    <span>{errors.userName && errors.userName.message}</span>
                                </div>
                                <div className="mb-3 col-lg-6">
                                    <label htmlFor="email" className="ps-3 fw-bold mb-2">Email</label>
                                    <input type="email" className="form-control" name="userEmail" {...register("userEmail")}></input>
                                </div>

                                <div className="mb-3 col-lg-12">
                                    <label htmlFor="message" className="ps-3 fw-bold mb-2">Message</label>
                                    <textarea className="form-control" cols="30" rows="10" id="message" name="message" {...register("message")} maxLength='1000'></textarea>
                                    <p className='message-chars-left'>Text remaining: {messageCharsLeft}</p>
                                </div>

                                <div className="col-lg-6">
                                    <input type="submit" className="btn btn-primary text-white py-3" value="Send Message"></input>
                                </div>

                            </div>
                            <div className="row">
                                <div className="col-6 col-lg-6 mt-3">
                                    <p className='status-message'>{statusMessage}</p>
                                </div>
                            </div>
                        </div>


                    </form>
                </div>
            </div>

            <div className="section">
                <div className="container">
                    <div className="row justify-content-center mb-5">
                        <div className="col-lg-7 text-center" data-aos="fade-up" data-aos-delay="100">
                            <span className="subheading">Salon</span>
                            <h3 className="heading mb-4">Welcome to Lashes West</h3>
                            <p className="w-75 mb-4 mx-auto">Enjoy our spacious salon as you are treated to beautiful lash extensions.</p>
                        </div>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col-lg-8" data-aos="fade-left" data-aos-delay="300">
                            <div className="img-wrap">
                                <img src="/assets/images/location/salon.jpg" alt="salon" className="img-fluid"></img>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="section">
                <div className="container">
                    <div className="row justify-content-center mb-5">
                        <div className="col-lg-7 text-center" data-aos="fade-up" data-aos-delay="100" data-aos-offset="800">
                            <h3 className="heading mb-4">Quiet, private setting</h3>
                            <p className="w-75 mb-4 mx-auto">Listen to your favorite music in the private service area.</p>
                        </div>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col-lg-8" data-aos="fade-left" data-aos-delay="300" data-aos-offset="800">
                            <div className="img-wrap">
                                <img src="/assets/images/location/salon-services-room.jpg" alt="salon" className="img-fluid"></img>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="section">
                <div className="container">
                    <div className="row justify-content-center mb-5">
                        <div className="col-lg-7 text-center" data-aos="fade-up" data-aos-delay="100" data-aos-offset="900">
                            <h3 className="heading mb-4">Waiting area</h3>
                            <p className="w-75 mb-4 mx-auto">Relax in comfort while waiting for your appointment.</p>
                        </div>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col-lg-8" data-aos="fade-left" data-aos-delay="300" data-aos-offset="1000">
                            <div className="img-wrap">
                                <img src="/assets/images/location/salon-waiting.jpg" alt="salon" className="img-fluid"></img>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
}

export default Location;