import React from 'react';
// import { Link } from 'react-router-dom';

function Footer(props) {
    return (
        <div>


            <div className="site-footer">

                {/* Preloader  */}
                <div id="overlayer"></div>
                <div className="loader">
                    <div className="spinner-border text-primary" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div>
                </div>


                <div className="container">

                    <div className="row">
                        <div className="col-lg-4">
                            <div className="widget">
                                <h3>Contact</h3>
                                <address>12993 Ridgedale Drive<br />Suite 107<br />Minnetonka, MN 55305</address>
                                <ul className="list-unstyled links">
                                    <li><a href="tel://11234567890">612-327-7431</a></li>
                                    <li><a href="mailto:info@mydomain.com">michelle@lasheswest.com</a></li>
                                </ul>
                            </div> {/* /.widget */}
                        </div> {/* /.col-lg-4 */}
                        <div className="col-lg-4">
                            <div className="widget">
                                <h3>Sources</h3>
                                <ul className="list-unstyled float-start links">
                                <li><a href="/contact-us#contact">Contact us</a></li>
                                    <li><a href="/lash-services">Services</a></li>
                                    <li><a href="/home#about-us">About us</a></li>
                                    <li><a href="/location">Location</a></li>
                                    <li><a href="/online-shop">Shop lash and beauty products online</a></li>
                                </ul>
                            </div> { /* /.widget */}
                        </div> { /* /.col-lg-4 */}
                        <div className="col-lg-4">
                            <div className="widget">
                                <h3>Links</h3>
                                <ul className="list-unstyled links">
                                <li><a href="www.lasheswest.com">Terms</a></li>
                                    <li><a href="www.lasheswest.com">Privacy</a></li>
                                </ul>

                                <ul className="list-unstyled social">
                                    <li><a href="https://www.instagram.com/lasheswest/"><span className="icon-instagram"></span></a></li>
                                    <li><a href="https://www.facebook.com/Lashes-West-329016520551939/"><span className="icon-facebook"></span></a></li>
                                    <li><a href="https://www.linkedin.com/in/michelle-petersen-36672217/"><span className="icon-linkedin"></span></a></li>
                                    <li><a href="https://www.pinterest.com/1d5srur5d44ri1h/lashes-west/"><span className="icon-pinterest"></span></a></li>
                                </ul>
                            </div> {/* /.widget */}
                        </div> {/* /.col-lg-4 */}
                    </div> { /* /.row */}

                    <div className="row mt-5">
                        <div className="col-12 text-center">
                            <p className="mb-0">Copyright &copy;{(new Date().getFullYear())} All rights reserved</p>

                        </div>
                    </div>
                </div> { /* /.container */}
            </div> { /* /.site-footer */}


        </div>
    );
}

export default Footer;