import React, { useState } from 'react';
import TinySlider from "tiny-slider-react";
import Typed from 'typed.js';
import { Button, Modal } from 'react-bootstrap';
import Michelle from './MichelleComponent';

const TypedReactHooksLashes = () => {
	// Create reference to store the DOM element containing the animation
	const el = React.useRef(null);
  // Create reference to store the Typed instance itself
	const typed = React.useRef(null);

  React.useEffect(() => {
    const options = {
    	strings: [
        "...long",
        "...healthy",
        "...lovely",
        "...full",
        "<strong>beautiful<strong>"
      ],
      typeSpeed: 80,
      backSpeed: 80,
      backDelay: 3000,
      startDelay: 1000,
      onComplete: function(self){ self.stop() }  
    };
    
    // elRef refers to the <span> rendered below
    typed.current = new Typed(el.current, options);
    
    return () => {
      // Make sure to destroy Typed instance during cleanup
      // to prevent memory leaks
      typed.current.destroy();
    }
  }, [])

  return (
    <div className="wrap">
      <div className="type-wrap">
        <span style={{ whiteSpace: 'pre' }} ref={el} />
      </div>

    </div>
  );
}

function RenderModalTitle({ modal }) {

    var returnMarkup;

    switch (modal) {
        case "michelle":
            returnMarkup = <>About Michelle</>
            break;
        case "about":
            returnMarkup = <>About Lash Extensions</>
            break;
        case "cbs":
            returnMarkup = <>CBS Review</>
            break;
        case "licensing":
            returnMarkup = <>Salon Licensing Information</>
            break;

        default:
            returnMarkup = <><p></p></>

    }

    return (
        returnMarkup
    );
}

function RenderModalMarkup({ modal }) {

    var returnMarkup;

    switch (modal) {
        case "michelle":
            returnMarkup = <Michelle></Michelle>
            break;
        case "about":
            returnMarkup = <><p>
                Eyelash extensions are single (synthetic) or multiple strands (synthetic or real fur) of pre-curled lashes to enhance your own natural eyelashes. They can look as natural or as dramatic as you want; all by adjusting the length, curvature, and density of the extensions.
                I meticulously attach the extensions to one natural lash at a time for lush, beautiful lashes. You will recline on a massage table with your eyes closed and the lower lashes covered to prevent the top lashes from adhering to the bottom lashes.
            </p>
                <img src="/assets/images/about-extensions.jpg" alt="michelle" className="img-fluid m-2"></img>
                <p>
                    Your eye shape, condition of your natural lashes, and personal preference are all considered to produce results that will be perfect for your lifestyle. The result is a natural look and feel that saves you time from putting on more makeup.
                </p>
                <p>
                    The look can last indefinitely with fills every 2-3 weeks. Like your scalp hair, natural eyelashes grow and shed in a cycle so when your natural lash sheds, the extension goes with it. Refills are necessary to replenish the fallen hairs and maintain the look.
                </p></>
            break;
        case "cbs":
            returnMarkup = <>
                <h5>They've done the research, your choice is easy</h5>
                <p>
                    CBS researched lash salons in Minnesota and ranked Lashes West as one of the best.
                </p>
                <p>
                    Read here how Lashes West can help you look better anytime - working out, at work or at play.
                </p>
                <p>
                    Lash extensions are age-defying, taking years off without adding make-up!
                </p>
                <a href="https://minnesota.cbslocal.com/top-lists/best-places-for-eyelash-extensions-in-minnesota" alt="CBS review" target="_blank" rel="noreferrer" className="btn btn-primary text-white"><strong>Read the review here</strong></a>

            </>
            break;
        case "licensing":
            returnMarkup = <>
                <h5>Are your salon and stylist properly licensed for lash extensions?</h5>
                <p>
                    Minnesota require a specialty license to perform eyelash extension services.
                </p>
                <p>
                    Many salons offer illegal lash services that can endanger the health of your lashes.
                </p>
                <p>
                    View our perfect inspection reports below<br />
                    (Minnesota requires this report to be posted in lash salon reception areas)
                </p>
                <div className="row">
                <div className="col-md-4 d-flex justify-content-center">
                        <a href="/assets/documents/InspectionReport2021.pdf" alt="2021 inspection report" target="_blank" rel="noreferrer" className="btn btn-primary text-white m-2"><strong>2021 report</strong></a>
                    </div>
                    <div className="col-md-4 d-flex justify-content-center">
                        <a href="/assets/documents/InspectionReport2019.pdf" alt="2019 inspection report" target="_blank" rel="noreferrer" className="btn btn-primary text-white m-2"><strong>2019 report</strong></a>
                    </div>
                    <div className="col-md-4 d-flex justify-content-center">
                        <a href="/assets/documents/InspectionReport2018.pdf" alt="2018 inspection report" target="_blank" rel="noreferrer" className="btn btn-primary text-white m-2"><strong>2018 report</strong></a>
                    </div>
                </div>
            </>
            break;

        default:
            returnMarkup = <><p>DEFAULT</p></>

    }

    return (
        returnMarkup
    );
}

const settings = {
    mode: 'carousel',
    lazyload: true,
    speed: 1000,
    animateDelay: 5000,
    items: 1,
    autoplay: true,
    controls: false,
    nav: true,
    navPosition: 'bottom',
    autoplayButtonOutput: false,
};

const css = `@media (max-width: 799px) {
    .backimage {
        background-image: url("/assets/images/hero_1_sm.jpg")
    }
}
@media (min-width: 800px) and (max-width:1199px) {
    .backimage {
        background-image: url("/assets/images/hero_1.jpg")
    }
}
@media (min-width: 1200px) {
    .backimage {
        background-image: url("/assets/images/hero_1_lg.jpg")
    }
}


`;

function Home(props) {


    const [show, setShow] = useState(false);
    const [modalInstance, setModalInstance] = useState("ModalInstance");

    const handleClose = () => setShow(false);
    const handleShow = modal => e => {
        setModalInstance(modal);
        setShow(true);
    }


    return (

        <>
            <div>
                <style scoped>{css}</style>
                <div className="hero backimage">
                    <div className="container">
                        <div className="row justify-content-center align-items-center">
                            <div className="col-lg-7 text-center">
                                <h1 className="heading mb-2" data-aos="fade-up">Your lashes will be<TypedReactHooksLashes />
                                </h1>
                                <p className="w-75 mb-4 mx-auto" data-aos="fade-up" data-aos-delay="100">Lashes West is a locally owned boutique salon that specializes in custom eyelash extensions. Michelle provides a private, quiet, relaxing experience that will be tailored to your vision of the perfect lashes.</p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-6 d-flex justify-content-end"><a href="/lash-services" className="btn btn-primary text-white" data-aos="fade-up" data-aos-delay="200">Services</a></div>
                            <div className="col-6 d-flex justify-content-start"><a href="/online-shop" className="btn btn-primary text-white" data-aos="fade-up" data-aos-delay="200">Online shop</a></div>
                        </div>
                    </div>
                </div>

                <div className="section sec-about overlay bg-img-section" style={{ backgroundImage: "url('/assets/images/hero_1_background.jpg')" }}>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6">
                                <span className="subheading d-block" data-aos="fade-up">Appointments</span>
                                <a href="/appointments"><h2 className="heading w-75 text-white mb-5" data-aos="fade-up" data-aos-delay="100">Book your appointment online now</h2></a>
                                <div className="img-wrap" data-aos="fade-up" data-aos-delay="200">
                                <a href="/appointments"><img src="/assets/images/book-appointment.jpg" alt="all about us" className="img-fluid"></img></a>
                                </div>
                            </div>

                            <div className="col-lg-4 mx-auto">
                                <div className="intro-box" data-aos="fade-up">
                                    <div className="v-line"></div>
                                    <p className="text-white-50">Your private appointment will always be with Michelle, the owner, and since you are my priority, I work around your schedule.</p>
                                    <p className="text-white-50">Whether you prefer a very natural look, glamorous Vegas-style lashes, or something in between – the extensions you get will be created for your own eyes to enhance your personal beauty.</p>
                                    <p className="text-white-50">You can also call, text or email me to schedule your appointment:</p>
                                    <p className="text-white-50 fw-bold">612-327-7431</p>
                                    <p className="text-white-50 fw-bold"><a href="mailto:michelle@lasheswest.com">michelle@lasheswest.com</a></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div id="about-us" className="section sec-services">
                    <div className="container">
                        <div className="row ">
                            <div className="col-lg-4 offset-md-7" data-aos="fade-up">
                                <div className="intro">
                                    <div className="v-line"></div>
                                    <p>As an owner operated small local business, I can give you custom lashes designed around your lifestyle. Lashes West has been established since 2009.</p>
                                    <p>My years of experience doing extensions enables me to help you make the healthy choice for your lashes to look their best long term.</p>
                                    <p>Working in cosmetics for many years has taught me that the eyes are the windows to ourselves and when they are beautiful, the rest just seems to fall into place!</p>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-lg-4">
                                <h2 className="heading" data-aos="fade-up">About Lashes West</h2>
                                <p className="lead" data-aos="fade-up">The Only Lash Salon You Will Ever Need</p>
                                <p data-aos="fade-up">Coming to an owner operated local salon you will get the undivided attention you deserve! I will listen to your wishes and create a custom lash look designed around your lifestyle. Developing beautifully enhanced eyelashes for ladies since 2009, I can help you to make healthy choices for your best-looking lashes long term.</p>
                                <p data-aos="fade-up">Working in cosmetics for many years has taught me that the eyes are windows to ourselves, and when they are beautiful, the rest just seems to fall into place!</p>
                            </div>
                        </div>
                        <div className="row g-0 align-items-stretch">
                            <div className="col-lg-6 align-self-center">
                                <div className="img-wrap" data-aos="fade-left">
                                    <img src="/assets/images/about_1.jpg" alt="all about me" className="img-fluid"></img>
                                </div>
                            </div>
                            <div className="col-lg-6" data-aos="fade-up">
                                <div className="box-slider-wrap">
                                    <TinySlider settings={settings}>
                                        <div className="item">
                                            <div className="service">
                                                <h3 className="text-white">About Michelle</h3>
                                                <p className="text-white-50">Learn more about Michelle's experience with eyelash extensions and beauty products.</p>
                                                <p className="text-white mt-5 mb-2"><Button className="text-white" variant="primary" onClick={handleShow("michelle")}>
                                                    About Michelle
                                                </Button></p>
                                            </div>
                                        </div>
                                        <div className="item">
                                            <div className="service ">
                                                <h3 className="text-white">About Eyelash Extensions</h3>
                                                <p className="text-white-50">Get more information about lash extensions, and see how they are applied.</p>
                                                <p className="text-white mt-5 mb-2"><Button className="text-white" variant="primary" onClick={handleShow("about")}>
                                                    More information
                                                </Button></p>
                                            </div>
                                        </div>
                                        <div className="item">
                                            <div className="service">
                                                <h3 className="text-white">They've Done the Research</h3>
                                                <p className="text-white-50">CBS researched lash salons in Minnesota and ranked Lashes West as one of the best.</p>
                                                <p className="text-white mt-5 mb-2"><Button className="text-white" variant="primary" onClick={handleShow("cbs")}>
                                                    CBS review
                                                </Button></p>
                                            </div>
                                        </div>

                                        <div className="item">
                                            <div className="service">
                                                <h3 className="text-white">Minnesota Licensing Requirements</h3>
                                                <p className="text-white-50">Are your salon and stylist properly licensed for lash extensions?</p>
                                                <p className="text-white mt-5 mb-2"><Button className="text-white" variant="primary" onClick={handleShow("licensing")}>
                                                    Minnesota licensing requirements
                                                </Button></p>
                                            </div>
                                        </div>
                                    </TinySlider>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="section py-0 sec-stats section-counter">
                    <div className="container">
                        <div className="row mb-5">
                            <div className="col-lg-7 text-center mx-auto" data-aos="fade-up">
                                <h2 className="heading">Client photo gallery</h2>
                            </div>
                        </div>

                        <div className="row g-2">
                            <div className="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-3" data-aos="fade-left" data-aos-delay="0">
                                <a href="/eyelash-extensions-client-photos">
                                    <div className="stat-counter">
                                    <img src="/assets/images/lashes/full.png" alt="client photos gallery" className="img-fluid"></img>
                                        <span className="caption">Complete photo gallery</span>
                                    </div>
                                </a>
                            </div>
                            <div className="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-3 mt-lg-5" data-aos="fade-left" data-aos-delay="100">
                                <a href="/eyelash-extensions-client-photos#mink">
                                    <div className="stat-counter">
                                        <img src="/assets/images/lashes/full.png" alt="mink fur" className="img-fluid"></img>
                                        <span className="caption">Real mink pictures</span>
                                    </div>
                                </a>
                            </div>
                            <div className="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-3" data-aos="fade-left" data-aos-delay="200">
                                <a href="/eyelash-extensions-client-photos#synthetic">
                                    <div className="stat-counter">
                                        <img src="/assets/images/lashes/full.png" alt="synthetic fur" className="img-fluid"></img>
                                        <span className="caption">Synthetic fur pictures</span>
                                    </div>
                                </a>
                            </div>
                            <div className="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-3 mt-lg-5" data-aos="fade-left" data-aos-delay="300">
                                <a href="/lash-services/full-set">
                                    <div className="stat-counter">
                                        <img src="/assets/images/lashes/full.png" alt="full set" className="img-fluid"></img>
                                        <span className="caption">Full set pictures</span>
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>




                <div className="section sec-media">
                    <div className="container">
                        <div className="row g-5 justify-content-around">
                            <div className="col-lg-4 align-self-center" data-aos="fade-up" data-aos-delay="0">
                                <h2 className="heading">RevitaLash Cosmetics</h2>
                                <p>RevitaLash Cosmetics was created by a physician to help his wife feel beautiful during her courageous fight against breast cancer.</p>
                                <p>RevitaLash® Cosmetics offers an entire collection designed to enhance the health and natural beauty of your lashes, brows, and hair—while giving back to the cause that started it all, every day of the year.</p>
                                <p>It is a consistent award-winning product, created by a doctor that is safe to use with eyelash extensions. InStyle 2021 Editor’s Pick Best Beauty Buys</p>
                                <p><a href="/services" className="btn btn-primary text-white">RevitaLash products</a></p>
                            </div>
                            <div className="col-lg-6">
                                <div className="img-wrap">
                                    <img src="/assets/images/revitalash_products.jpg" alt="RevitaLash products" className="img-fluid img-1" data-aos="fade-up" data-aos-delay="100"></img>

                                    <img src="/assets/images/revitalash_model.jpg" alt="RevitaLash conditioner" className="img-2" data-aos="fade-up" data-aos-delay="200"></img>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title><RenderModalTitle modal={modalInstance}></RenderModalTitle></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <RenderModalMarkup modal={modalInstance}></RenderModalMarkup>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" className="text-white" onClick={handleClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </>


    );
}

export default Home;