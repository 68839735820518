import React from "react";
// import Gallery from "react-grid-gallery";
// import { photosMink } from "./photosMink";
// import { photosSynthetic } from "./photosSynthetic";

function ClientGallery(props) {



    return (
        <div>

            <div className="section">
                <div className="container">
                    <div className="row justify-content-center align-items-center">
                        <div className="col-lg-7 text-center">
                            <h1 className="heading mb-2">Client Photo Gallery</h1>
                            <div data-aos="fade-up" data-aos-delay="200">
                                <div className="w-auto mb-4 mx-auto">
                                    <p>Examples of real mink fur and synthetic faux mink lashes are pictured.</p>
                                    <p>Full and light sets demonstrate how dramatic or natural eyelash extensions can be. Before and after photos show improvements in length, volume, and curl with real and faux mink lash extensions.</p>
                                </div>
                                <div className="row mb-3">
                                    <div className="col-4 d-flex justify-content-center"><a href="#mink" className="btn-small btn-primary text-white">real mink photos</a></div>
                                    <div className="col-4 d-flex justify-content-center"><a href="#synthetic" className="btn-small btn-primary btn-sm text-white">synthetic photos</a></div>
                                    <div className="col-4 d-flex justify-content-center"><a href="/lash-services" className="btn-small btn-primary btn-sm text-white">services</a></div>
                                </div>
                                <div id="mink">
                                    <div className="w-auto mb-4 mx-auto">
                                        <p>Real mink fur and sable fur are the most luxurious choice for beautiful eyelashes. Natural fibers are imperfect and fine, giving you a soft, dramatic frame that can be exceptionally long and fluffy or more dense with a shorter length. Real fur is light weight and versatile for everyday wear that many will mistake as your natural lashes. Fur is used in custom made bundles for volume in your lashes to pop your eyes.  Brides love real fur for their wedding day and appreciate the ease of getting ready for every day after the wedding. </p>
                                    </div>

                                    {/* <Gallery images={photosMink} /> */}
                                    <h1>FIND A NEW GALLERY THAT WORKS WITH NPM 17+</h1>

                                </div>
                            </div>

                            <div className="row mb-3 mt-5">
                                <div className="col-4 d-flex justify-content-center"><a href="#mink" className="btn-small btn-primary text-white" data-aos="fade-up" data-aos-delay="200">real mink photos</a></div>
                                <div className="col-4 d-flex justify-content-center"><a href="#synthetic" className="btn-small btn-primary btn-sm text-white" data-aos="fade-up" data-aos-delay="200">synthetic photos</a></div>
                                <div className="col-4 d-flex justify-content-center"><a href="/lash-services" className="btn-small btn-primary btn-sm text-white" data-aos="fade-up" data-aos-delay="200">services</a></div>
                            </div>
                            <div id="synthetic">
                                <div className="w-auto mb-4 mx-auto" data-aos="fade-up" data-aos-delay="100">
                                    <p>SYNTHETIC lorem ipsum... </p>
                                </div>

                                {/* <Gallery images={photosSynthetic} /> */}


                            </div>
                        </div>
                    </div>
                </div>


            </div>

        </div>

    );
}

export default ClientGallery;