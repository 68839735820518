import React from 'react';
import TinySlider from "tiny-slider-react";

const settings = {
    mode: 'carousel',
    lazyload: true,
    speed: 800,
    animateDelay: 2000,
    items: 1,
    autoplay: true,
    controls: false,
    nav: true,
    navPosition: 'bottom',
    autoplayButtonOutput: false,
};

function Services(props) {
    return (
        <div>

            <div className="section">
                <div className="container">
                    <div className="row justify-content-center align-items-center">
                        <div className="col-lg-7 text-center">
                            <h1 className="heading mb-2" data-aos="fade-up">Services</h1>
                            <p className="w-75 mb-4 mx-auto" data-aos="fade-up" data-aos-delay="100">Lashes West offers a variety of eyelash extensions to fit your needs.  Classic synthetic lashes are 1:1 ratio, similar to the look of “separating” mascara. Volume lashes are multiple thin extensions created into a fan and applied to one natural eyelash.</p>
                            <p className="w-75 mb-4 mx-auto" data-aos="fade-up" data-aos-delay="100">If you like volumizing or “drama” mascara, you will love volume eyelash extensions.  These are made of synthetic faux-mink fibers or real mink fur, depending on your preference and can give intense volume while still being fluffy and lightweight.</p>
                            <p><a href="/appointments" className="btn btn-primary text-white" data-aos="fade-up" data-aos-delay="200">Make an appointment</a></p>
                        </div>
                    </div>
                </div>
            </div>


            <div className="section">
                <div className="container">
                    <div className="row g-1 align-items-stretch">
                        <div className="col-lg-4 order-1 order-lg-2" data-aos="fade-up">
                            <div className="h-100"><div className="frame h-100"><div className="feature-img-bg h-100" style={{ backgroundImage: "url('/assets/images/about_2.jpg')" }}></div></div></div>
                        </div>

                        <div className="col-6 col-sm-6 col-lg-4 feature-1-wrap d-md-flex flex-md-column order-lg-1" >

                            <div className="feature-1 d-md-flex custom-mb" data-aos="fade-up" data-aos-delay="100">
                                <div className="align-self-center">
                                    <a href="/lash-services/light-set">
                                        <img src="/assets/images/lashes/full-small.png" alt="full" className="img-fluid mb-3"></img>
                                        <h3 className="text-secondary">Light Set</h3>
                                    </a>
                                    <p className="mb-0">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer consectetur blandit semper.</p>
                                </div>
                            </div>

                            <div className="feature-1 " data-aos="fade-up" data-aos-delay="200">
                                <div className="align-self-center">
                                    <a href="/lash-services/full-set">
                                        <img src="/assets/images/lashes/full-small.png" alt="full" className="img-fluid mb-3"></img>
                                        <h3 className="text-secondary">Full Set</h3>
                                    </a>
                                    <p className="mb-0">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer consectetur blandit semper.</p>
                                </div>
                            </div>

                        </div>

                        <div className="col-6 col-sm-6 col-lg-4 feature-1-wrap d-md-flex flex-md-column order-lg-3" >

                            <div className="feature-1 d-md-flex custom-mb" data-aos="fade-up" data-aos-delay="100">
                                <div className="align-self-center">
                                    <a href="/lash-services/fills">
                                        <img src="/assets/images/lashes/full-small.png" alt="full" className="img-fluid mb-3"></img>
                                        <h3 className="text-secondary">Fills</h3>
                                    </a>
                                    <p className="mb-0">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer consectetur blandit semper.</p>
                                </div>
                            </div>

                            <div className="feature-1 d-md-flex" data-aos="fade-up" data-aos-delay="200">
                                <div className="align-self-center">
                                    <a href="/lash-services/specialty">
                                        <img src="/assets/images/lashes/full-small.png" alt="full" className="img-fluid mb-3"></img>
                                        <h3 className="text-secondary">Specialty</h3>
                                    </a>
                                    <p className="mb-0">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer consectetur blandit semper.</p>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
            </div>

            <div className="section">
                <div className="container">
                    <div className="row justify-content-center mb-5">
                        <div className="col-lg-7 text-center">
                            <span className="subheading">Lashes West clients</span>
                            <h3 className="heading mb-4">Photo gallery</h3>
                        </div>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col-lg-8">

                            <div className="gallery-slider-wrap">
                                <div className="testimonial-slider" id="testimonial-slider">
                                    <TinySlider settings={settings}>
                                        <div className="item mb-5">
                                            <div className="gallery-slider text-center">
                                                <a href="/eyelash-extensions-client-photos">
                                                    <div className="img-wrap mb-3">
                                                        <img src="/assets/images/gallery/gallery45.jpg" alt="gallery" className="img-fluid mx-auto"></img>
                                                    </div>
                                                    <div className="gallery-slider-description">
                                                        <span className="d-block position">Complete gallery - all photos</span>
                                                    </div>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="item mb-5">
                                            <div className="gallery-slider text-center">
                                                <a href="/eyelash-extensions-client-photos#mink">
                                                    <div className="img-wrap mb-3">
                                                        <img src="/assets/images/gallery/real-mink-fur/janny1.jpg" alt="real mink" className="img-fluid mx-auto"></img>
                                                    </div>
                                                    <div className="gallery-slider-description">
                                                        <span className="d-block position">Real mink fur extensions</span>
                                                    </div>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="item mb-5">
                                            <div className="gallery-slider text-center">
                                                <a href="/eyelash-extensions-client-photos#synthetic">
                                                    <div className="img-wrap mb-3">
                                                        <img src="/assets/images/gallery/synthetic/betsy1.jpg" alt="synthetic" className="img-fluid mx-auto"></img>
                                                    </div>
                                                    <div className="gallery-slider-description">
                                                        <span className="d-block position">Synthetic extensions</span>
                                                    </div>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="item mb-5">
                                            <div className="gallery-slider text-center">
                                                <a href="/lash-services/full-set#gallery">
                                                    <div className="img-wrap mb-3">
                                                        <img src="/assets/images/gallery/gallery27.jpg" alt="full set" className="img-fluid mx-auto"></img>
                                                    </div>
                                                    <div className="gallery-slider-description">
                                                        <span className="d-block position">Full set extensions</span>
                                                    </div>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="item mb-5">
                                            <div className="gallery-slider text-center">
                                                <a href="/lash-services/light-set#gallery">
                                                    <div className="img-wrap mb-3">
                                                        <img src="/assets/images/services/light-set2.jpg" alt="light set" className="img-fluid mx-auto"></img>
                                                    </div>
                                                    <div className="gallery-slider-description">
                                                        <span className="d-block position">Light set extensions</span>
                                                    </div>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="item mb-5">
                                            <div className="gallery-slider text-center">
                                                <a href="/lash-services/specialty#gallery">
                                                    <div className="img-wrap mb-3">
                                                        <img src="/assets/images/gallery/synthetic/mega-volume-and-bottom.jpg" alt="specialty" className="img-fluid mx-auto"></img>
                                                    </div>
                                                    <div className="gallery-slider-description">
                                                        <span className="d-block position">Mega volume, bottom lashes, blend and accent lashes</span>
                                                    </div>
                                                </a>
                                            </div>
                                        </div>
                                    </TinySlider>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
}

export default Services;