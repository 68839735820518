import React from 'react';

function Shop(props) {

    return (
        <div>
            <div className="section">
                <div className="container">
                    <div className="row justify-content-center align-items-center">
                        <div className="col-lg-7 text-center">
                            <h1 className="heading mb-2" data-aos="fade-up">Shop</h1>
                            <p className="w-75 mb-4 mx-auto" data-aos="fade-up" data-aos-delay="100">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris suscipit ullamcorper nibh in ultricies. Donec nulla ex, aliquet tincidunt cursus id, viverra posuere diam.</p>
                        </div>
                    </div>
                    <div className="row justify-content-center align-items-center">
                        <div className="col-lg-7 text-center">
                            <h2>{props.response}</h2>
                        </div>
                    </div>
                    <div className="row justify-content-center align-items-center">
                        <div className="col-lg-7 text-center">
                            <h3>DATA:</h3>
                            <h2>{props.sampledata}</h2>
                        </div>
                    </div>
                    <div className="row justify-content-center align-items-center mt-5">
                        <div className="col-lg-7 text-center">
                        <h4>These users are currently online:</h4>
                        </div>
                    </div>
                    <div className="row justify-content-center align-items-center">
                        <div className="col-lg-7 text-center">
                            {props.users.map(user =>
                                <div key={user.id}>{user.username}</div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}


export default Shop;