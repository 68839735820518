import React, { Component } from 'react';
import { Route, Routes, useLocation, useParams, useNavigate, Outlet, Navigate } from 'react-router-dom';


import Header from './HeaderComponent';
import AdminHeader from './Admin/AdminHeaderComponent';
import Footer from './FooterComponent';
import { PRELOADER, SITEMENUCLONE } from './navigation';

import Admin from './Admin/AdminComponent';
import AdminLogin from './Admin/AdminLoginComponent';
import AdminClients from './Admin/AdminClientsComponent';
import AdminClientDetail from './Admin/AdminClientDetailComponent';
import AdminAppointments from './Admin/AdminAppointmentsComponent';
import AdminSchedules from './Admin/AdminSchedulesComponent';

import Home from './HomeComponent';
import Location from './LocationComponent';
import Services from './ServicesComponent';
import Specialty from './SpecialtyComponent';
import Appointments from './AppointmentsComponent';
import Shop from './ShopComponent';
import { connect } from 'react-redux';
import AOS from 'aos';
import ServiceDetails from './ServiceDetailsComponent';
import ClientGallery from './ClientGalleryComponent';

function AppointmentsWithBookingTime() {
  const { bookingTime } = useParams();
  return (
    <Appointments bookingTime={bookingTime}></Appointments>
  )
}

function AdminClientWithId() {
  const { cid } = useParams();
  return (
    <AdminClientDetail cid={cid}></AdminClientDetail>
  )
}

const mapStateToProps = state => {
  return {
    servicePricing: state.servicePricing
  }
}

function withRouter(Component) {
  function ComponentWithRouterProp(props) {
    let location = useLocation();
    let navigate = useNavigate();
    let params = useParams();
    return (
      <Component
        {...props}
        router={{ location, navigate, params }}
      />
    );
  }

  return ComponentWithRouterProp;
}

//const SomeComponent = withRouter(props => <Main {... props}/>);

class Main extends Component {

  constructor(props) {
    super(props);
    this.state = {
      user: null,
      devBypass: false

    };
  }

  componentDidMount() {

    AOS.init({
      // initialise with other settings
      duration: 800,
      easing: 'slide',
      once: true
    });

    PRELOADER();
    SITEMENUCLONE();
  }

  callApi = async () => {
    const response = await fetch('/.netlify/functions/server/api/hello');
    const body = await response.json();
    if (response.status !== 200) throw Error(body.message);
    return body;
  };

  handleSubmit = async e => {
    e.preventDefault();
    const response = await fetch('/.netlify/functions/server/api/world', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ post: this.state.post }),
    });
    const body = await response.text();

    this.setState({ responseToPost: body });
  };


  render() {

    const HomePage = () => {
      return (
        <div>
          <Home />
        </div>
      );
    }



    const ShopPopulated = () => {
      return (
        <div>
          <Shop users={this.state.users} response={this.state.response} ></Shop>

          <p>{this.state.response}</p>
          <form onSubmit={this.handleSubmit}>
            <p>
              <strong>Post to Server:</strong>
            </p>
            <input
              type="text"
              value={this.state.post}
              onChange={e => this.setState({ post: e.target.value })}
            />
            <button type="submit">Submit</button>
          </form>
          <p>{this.state.responseToPost}</p>

        </div>

      )
    };

    const ServiceWithId = ({ match }) => {

      let location = useLocation();
      var selectedService = this.props.servicePricing.find(({ url }) => url === location.pathname);

      if (selectedService === undefined) {
        selectedService = this.props.servicePricing.services[0];
      }

      return (
        <ServiceDetails service={selectedService} services={this.props.servicePricing} />
      );
    };

    const PublicLayout = () => (
      <>
        <Header />
        <main>
          <div>
            <Outlet />
          </div>
        </main>
      </>
    )

    const AdminLayout = () => (
      <>
        <AdminHeader />
        <main>
          <div>
            <Outlet />
          </div>
        </main>
      </>
    )


    return (
      <>
        <Routes>
          <Route element={<AdminLayout />}>
            <Route path="/admin" element={localStorage.getItem("user") || process.env.REACT_APP_DEV_BYPASS === "BYPASS" ? <Admin /> : <Navigate to="/admin/login" />} />
            <Route path="/admin/login" element={localStorage.getItem("user") ? <Navigate to="/admin" /> : <AdminLogin />} />
            <Route path="/admin/clients" element={localStorage.getItem("user") || process.env.REACT_APP_DEV_BYPASS === "BYPASS" ? <AdminClients /> : <Navigate to="/admin/login" />} />
            <Route path="/admin/clientdetail/:cid" element={localStorage.getItem("user") || process.env.REACT_APP_DEV_BYPASS === "BYPASS" ? <AdminClientWithId /> : <Navigate to="/admin/login" />} />
            <Route path="/admin/appointments" element={localStorage.getItem("user") || process.env.REACT_APP_DEV_BYPASS === "BYPASS" ? <AdminAppointments /> : <Navigate to="/admin/login" />} />
            <Route path="/admin/schedules" element={localStorage.getItem("user") || process.env.REACT_APP_DEV_BYPASS === "BYPASS" ? <AdminSchedules /> : <Navigate to="/admin/login" />} />
          </Route>
          <Route element={<PublicLayout />} >
            <Route path="/" element={<HomePage />} />
            <Route path="/lash-services/specialty/:serviceUrl" element={<ServiceWithId />} />
            <Route path="/lash-services/specialty" element={<Specialty />} />
            <Route path="/lash-services/:serviceUrl" element={<ServiceWithId />} />
            <Route path="/lash-services" element={<Services />} />
            <Route path="/appointments" element={<Appointments />} />
            <Route path="/appointments/:bookingTime" element={<AppointmentsWithBookingTime />} />
            <Route exact path="/about-us" element={<Location />} />
            <Route exact path="/location" element={<Location />} />
            <Route exact path="/contact-us" element={<Location />} />
            <Route path="/online-shop" element={<ShopPopulated />} />
            <Route path="/eyelash-extensions-client-photos" element={<ClientGallery />} />
          </Route>
        </Routes>
        <Footer />
      </>
    );
  }
}

export default withRouter(connect(mapStateToProps)(Main));
