import React from 'react';
import TinySlider from "tiny-slider-react";

const settings = {
    mode: 'carousel',
    lazyload: true,
    speed: 800,
    animateDelay: 2000,
    items: 1,
    autoplay: true,
    controls: false,
    nav: true,
    navPosition: 'bottom',
    autoplayButtonOutput: false,
};

function Specialty(props) {
    return (
        <div>

            <div className="section">
                <div className="container">
                    <div className="row justify-content-center align-items-center">
                        <div className="col-lg-7 text-center">
                            <h1 className="heading mb-2" data-aos="fade-up">Specialty</h1>
                            <p className="w-75 mb-4 mx-auto" data-aos="fade-up" data-aos-delay="100">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer consectetur blandit semper. Morbi orci augue, vehicula elementum faucibus ac, faucibus quis lacus. Sed nec orci sapien. Curabitur ornare laoreet dui, ut venenatis quam congue sed. Integer sem massa, varius quis justo eget, eleifend ullamcorper velit. Donec congue neque id aliquet scelerisque. Proin in sagittis nulla. Nam sed molestie mi, a tempor ex. Fusce maximus condimentum viverra. Mauris in nibh id nibh venenatis semper. Mauris ut aliquet nibh, quis pharetra quam. Nulla iaculis ex ligula, nec ornare ipsum semper nec.</p>
                            <p><a href="/services" className="btn btn-primary text-white" data-aos="fade-up" data-aos-delay="200">Get started</a></p>
                        </div>
                    </div>
                </div>
            </div>


            <div className="section">
                <div className="container">
                    <div className="row g-1 align-items-stretch">
                        <div className="col-lg-4 order-1 order-lg-2" data-aos="fade-up">
                            <div className="h-100"><div className="frame h-100"><div className="feature-img-bg h-100" style={{ backgroundImage: "url('/assets/images/about_2.jpg')" }}></div></div></div>
                        </div>

                        <div className="col-6 col-sm-6 col-lg-4 feature-1-wrap d-md-flex flex-md-column order-lg-1" >

                            <div className="feature-1 d-md-flex custom-mb" data-aos="fade-up" data-aos-delay="100">
                                <div className="align-self-center">
                                    <a href="/lash-services/specialty/blend">
                                        <img src="/assets/images/lashes/full-small.png" alt="full" className="img-fluid mb-3"></img>
                                        <h3 className="text-secondary">Blend</h3>
                                    </a>
                                    <p className="mb-0">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer consectetur blandit semper.</p>
                                </div>
                            </div>

                            <div className="feature-1 " data-aos="fade-up" data-aos-delay="200">
                                <div className="align-self-center">
                                    <a href="/lash-services/specialty/mega-volume">
                                        <img src="/assets/images/lashes/full-small.png" alt="full" className="img-fluid mb-3"></img>
                                        <h3 className="text-secondary">Mega Volume</h3>
                                    </a>
                                    <p className="mb-0">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer consectetur blandit semper.</p>
                                </div>
                            </div>

                        </div>

                        <div className="col-6 col-sm-6 col-lg-4 feature-1-wrap d-md-flex flex-md-column order-lg-3" >

                            <div className="feature-1 d-md-flex custom-mb" data-aos="fade-up" data-aos-delay="100">
                                <div className="align-self-center">
                                    <a href="/lash-services/specialty/synthetic-accent-lashes">
                                        <img src="/assets/images/lashes/full-small.png" alt="full" className="img-fluid mb-3"></img>
                                        <h3 className="text-secondary">Synthetic Accent Lashes</h3>
                                    </a>
                                    <p className="mb-0">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer consectetur blandit semper.</p>
                                </div>
                            </div>

                            <div className="feature-1 d-md-flex" data-aos="fade-up" data-aos-delay="200">
                                <div className="align-self-center">
                                    <a href="/lash-services/specialty/bottom-lashes">
                                        <img src="/assets/images/lashes/full-small.png" alt="full" className="img-fluid mb-3"></img>
                                        <h3 className="text-secondary">Bottom Lashes</h3>
                                    </a>
                                    <p className="mb-0">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer consectetur blandit semper.</p>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
            </div>

            <div className="section">
                <div className="container">
                    <div className="row justify-content-center mb-5">
                        <div className="col-lg-7 text-center">
                            <span className="subheading">Lashes West clients</span>
                            <h3 className="heading mb-4">Photo gallery</h3>
                        </div>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col-lg-8">

                            <div className="gallery-slider-wrap">
                                <div className="testimonial-slider" id="testimonial-slider">
                                    <TinySlider settings={settings}>
                                        <div className="item mb-5">
                                            <div className="gallery-slider text-center">
                                                <a href="/lash-services/specialty/mega-volume#gallery">
                                                    <div className="img-wrap mb-3">
                                                        <img src="/assets/images/services/mega-volume1.jpg" alt="mega volume" className="img-fluid mx-auto"></img>
                                                    </div>
                                                    <div className="gallery-slider-description">
                                                        <span className="d-block position">Mega volume</span>
                                                    </div>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="item mb-5">
                                            <div className="gallery-slider text-center">
                                                <a href="/lash-services/specialty/blend#gallery">
                                                    <div className="img-wrap mb-3">
                                                        <img src="/assets/images/services/blend1.jpg" alt="blend" className="img-fluid mx-auto"></img>
                                                    </div>
                                                    <div className="gallery-slider-description">
                                                        <span className="d-block position">Lash blend</span>
                                                    </div>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="item mb-5">
                                            <div className="gallery-slider text-center">
                                                <a href="/lash-services/specialty/synthetic-accent-lashes#gallery">
                                                    <div className="img-wrap mb-3">
                                                        <img src="/assets/images/gallery/gallery27.jpg" alt="accent lashes" className="img-fluid mx-auto"></img>
                                                    </div>
                                                    <div className="gallery-slider-description">
                                                        <span className="d-block position">Synthetic accent lashes</span>
                                                    </div>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="item mb-5">
                                            <div className="gallery-slider text-center">
                                                <a href="/lash-services/specialty/synthetic-bottom-lashes#gallery">
                                                    <div className="img-wrap mb-3">
                                                        <img src="/assets/images/services/bottom-lashes1.jpg" alt="bottom lashes" className="img-fluid mx-auto"></img>
                                                    </div>
                                                    <div className="gallery-slider-description">
                                                        <span className="d-block position">Bottom lashes</span>
                                                    </div>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="item mb-5">
                                            <div className="gallery-slider text-center">
                                                <a href="/eyelash-extensions-client-photos">
                                                    <div className="img-wrap mb-3">
                                                        <img src="/assets/images/gallery/gallery45.jpg" alt="gallery" className="img-fluid mx-auto"></img>
                                                    </div>
                                                    <div className="gallery-slider-description">
                                                        <span className="d-block position">Complete gallery - all photos</span>
                                                    </div>
                                                </a>
                                            </div>
                                        </div>
                                    </TinySlider>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>


        </div>

    );
}

export default Specialty;