export const PRELOADER = function () {

    var loader = document.querySelector('.loader');
    var overlay = document.getElementById('overlayer');
    var delayCount = 0;

    function fadeOut(el) {
      el.style.opacity = 1;
      (function fade() {
        if ((el.style.opacity -= .1) < 0) {
          el.style.display = "none";
        } else {
          requestAnimationFrame(fade);
        }
      })();
    };

    function fadePage() {

      if ((loader === null || overlay === null) && delayCount < 10) {
        delay();
      }

      setTimeout(function () {
        fadeOut(loader);
        fadeOut(overlay);
      }, 200);
    };

    function delay() {
      setTimeout(function () {
        loader = document.querySelector('.loader');
        overlay = document.getElementById('overlayer');
        delayCount++;
        fadePage();
      }, 200);
    };

    fadePage();

  }

  export const SITEMENUCLONE = function () {
    var jsCloneNavs = document.querySelectorAll('.js-clone-nav');
    var siteMobileMenuBody = document.querySelector('.site-mobile-menu-body');



    jsCloneNavs.forEach(nav => {
      var navCloned = nav.cloneNode(true);
      navCloned.setAttribute('class', 'site-nav-wrap');
      siteMobileMenuBody.appendChild(navCloned);
    });

    setTimeout(function () {

      var hasChildrens = document.querySelector('.site-mobile-menu').querySelectorAll(' .has-children');

      var counter = 0;
      hasChildrens.forEach(hasChild => {

        var refEl = hasChild.querySelector('a');

        var newElSpan = document.createElement('span');
        newElSpan.setAttribute('class', 'arrow-collapse collapsed');

        // prepend equivalent to jquery
        hasChild.insertBefore(newElSpan, refEl);

        var arrowCollapse = hasChild.querySelector('.arrow-collapse');
        arrowCollapse.setAttribute('data-bs-toggle', 'collapse');
        arrowCollapse.setAttribute('data-bs-target', '#collapseItem' + counter);

        var dropdown = hasChild.querySelector('.dropdown');
        if(dropdown){
          dropdown.setAttribute('class', 'collapse');
          dropdown.setAttribute('id', 'collapseItem' + counter);
          }

        counter++;
      });

    }, 1000);


    // Click js-menu-toggle

    var menuToggle = document.querySelectorAll(".js-menu-toggle");
    var mTog;
    menuToggle.forEach(mtoggle => {
      mTog = mtoggle;
      mtoggle.addEventListener("click", (e) => {
        if (document.body.classList.contains('offcanvas-menu')) {
          document.body.classList.remove('offcanvas-menu');
          mtoggle.classList.remove('active');
          mTog.classList.remove('active');
        } else {
          document.body.classList.add('offcanvas-menu');
          mtoggle.classList.add('active');
          mTog.classList.add('active');
        }
      });
    })


    //Check to see if the user has clicked off of the navigation menu.
    var specifiedElement = document.querySelector(".site-mobile-menu");
    var mt, mtoggleTemp;
    document.addEventListener('click', function (event) {
      var isClickInside = specifiedElement.contains(event.target);
      menuToggle.forEach(mtoggle => {
        mtoggleTemp = mtoggle
        mt = mtoggle.contains(event.target);
      })

      //If they have clicked outside of the menu, close it (remove the offcanvas-menu class)
      if (!isClickInside && !mt) {
        if (document.body.classList.contains('offcanvas-menu')) {
          document.body.classList.remove('offcanvas-menu');
          mtoggleTemp.classList.remove('active');
        }
      }

    });

  };
