export const SERVICEPRICING =
    [
        {
            id: 0,
            name: 'light set',
            url: '/lash-services/light-set',
            category: 'lightset',
            price: null,
            description: 'A “lighter” version of the full set. Lash extensions on most of your lashes (75%) equally distributed across your lash line. This is a good way to introduce yourself to the wonderful world of eyelash extensions.',
            feature1:'Classic synthetic:',
            feature1price:'150',
            feature1description:null,
            feature2:'Volume synthetic:',
            feature2price:'225',
            feature2description:null,
            feature3:'Real fur:',
            feature3price:'275',
            feature3description:null,
            description2:'90 minute appointment',
            description3:null,
            description4:null,
            description5:null,
            image: '/assets/images/services/light-set-featured.jpg',
            imageGallery: [
                {
                    key: 1,
                    image: '/assets/images/services/light-set1.jpg',
                    name: 'light set 1',
                    description: 'Light set, volume faux-mink'
                },
                {
                    key: 2,
                    image: '/assets/images/services/light-set2.jpg',
                    name: 'light set 2',
                    description: 'Light set, volume faux-mink'
                }
            ]
        },
        {
            id: 1,
            name: 'full set',
            url: '/lash-services/full-set',
            category: 'fullset',
            price: null,
            description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean consectetur ligula neque, et gravida libero pharetra at. ',
            feature1:'Classic synthetic:',
            feature1price:'225',
            feature1description:null,
            feature2:'Volume synthetic:',
            feature2price:'300',
            feature2description:null,
            feature3:'Mega volume synth:',
            feature3price:'375',
            feature3description:'(extra time required and includes bottom lashes)',
            feature4:'Real fur:',
            feature4price:'350',
            feature4description:null,
            description2:'90 minute appointment',
            description3:null,
            description4:null,
            description5:null,
            image: '/assets/images/services/full-set-featured.jpg',
            imageGallery: [
                {
                    key: 1,
                    image: '/assets/images/services/full-set1.jpg',
                    description: 'Classic full set'
                },
                {
                    key: 2,
                    image: '/assets/images/services/full-set2.jpg',
                    description: 'Volume full set'
                },
                {
                    key: 3,
                    image: '/assets/images/services/full-set3.jpg',
                    description: 'Real fur full set'
                },
                {
                    key: 4,
                    image: '/assets/images/services/full-set4.jpg',
                    description: 'Volume full set'
                },
                {
                    key: 5,
                    image: '/assets/images/services/full-set5.jpg',
                    description: 'Full set'
                },
                {
                    key: 6,
                    image: '/assets/images/services/full-set6.jpg',
                    description: 'Before and after - classic full set'
                },
                {
                    key: 7,
                    image: '/assets/images/services/full-set7.jpg',
                    description: 'Classic full set'
                },
                {
                    key: 8,
                    image: '/assets/images/services/full-set8.jpg',
                    description: 'Classic full set'
                },
                {
                    key: 9,
                    image: '/assets/images/services/full-set9.jpg',
                    description: 'Before and after volume'
                }
            ]
        },
        {
            id: 2,
            name: 'Fills',
            url: '/lash-services/fills',
            category: 'fills',
            price: null,
            description: 'Everyone sheds lashes differently so your fill appointment will be customized to your needs. Allergies, makeup wear, sleeping habits, and oily eyelids can all take a toll on how the extensions wear for you.',
            feature1:'Volume: Starting at',
            feature1price:'100',
            feature1description:null,
            feature2:'Classic: Starting at',
            feature2price:'75',
            feature2description:null,
            feature3:null,
            feature3price:null,
            feature3description:null,
            feature4:null,
            feature4price:null,
            feature4description:null,
            description2:'60 minute appointment',
            description3:null,
            description4:'Recommended every 2-3 weeks.',
            description5:'I individually count your lashes and only charge you for what you need.',
            image: '/assets/images/services/fills-featured.jpg',
            imageGallery: [
                {
                    key: 1,
                    image: '/assets/images/services/fills1.jpg',
                    description: '1 Lorem ipsum dolor sit amet'
                },
                {
                    key: 2,
                    image: '/assets/images/services/fills2.jpg',
                    description: '2 Lorem ipsum dolor sit amet'
                },
                {
                    key: 3,
                    image: '/assets/images/services/fills3.jpg',
                    description: '3 Lorem ipsum dolor sit amet'
                },
                {
                    key: 4,
                    image: '/assets/images/services/fills4.jpg',
                    description: '4 Lorem ipsum dolor sit amet'
                }
            ]
        },
        {
            id: 3,
            name: 'Blend',
            url: '/lash-services/specialty/blend',
            category: 'specialty',
            price: '9.99',
            description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean consectetur ligula neque, et gravida libero pharetra at. ',
            feature1:'Feature 1 here',
            feature2:'Feature 2 here',
            feature3:'Feature 3 here',
            image: '/assets/images/services/blend-featured.jpg',
            imageGallery: [
                {
                    key: 1,
                    image: '/assets/images/services/blend1.jpg',
                    description: '1 Lorem ipsum dolor sit amet'
                },
                {
                    key: 2,
                    image: '/assets/images/services/blend2.jpg',
                    description: '2 Lorem ipsum dolor sit amet'
                },
                {
                    key: 3,
                    image: '/assets/images/services/blend3.jpg',
                    description: '3 Lorem ipsum dolor sit amet'
                },
                {
                    key: 4,
                    image: '/assets/images/services/blend4.jpg',
                    description: '4 Lorem ipsum dolor sit amet'
                }
            ]
        },
        {
            id: 4,
            name: 'Mega volume',
            url: '/lash-services/specialty/mega-volume',
            category: 'specialty',
            price: '9.99',
            description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean consectetur ligula neque, et gravida libero pharetra at. ',
            feature1:'Feature 1 here',
            feature2:'Feature 2 here',
            feature3:'Feature 3 here',
            image: '/assets/images/services/mega-volume-featured.jpg',
            imageGallery: [
                {
                    key: 1,
                    image: '/assets/images/services/mega-volume1.jpg',
                    description: '1 Lorem ipsum dolor sit amet'
                },
                {
                    key: 2,
                    image: '/assets/images/services/mega-volume2.jpg',
                    description: '2 Lorem ipsum dolor sit amet'
                },
                {
                    key: 3,
                    image: '/assets/images/services/mega-volume3.jpg',
                    description: '3 Lorem ipsum dolor sit amet'
                },
                {
                    key: 4,
                    image: '/assets/images/services/mega-volume4.jpg',
                    description: '4 Lorem ipsum dolor sit amet'
                }
            ]
        },
        {
            id: 5,
            name: 'Synthetic accent lashes',
            url: '/lash-services/specialty/synthetic-accent-lashes',
            category: 'specialty',
            price: '75',
            description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean consectetur ligula neque, et gravida libero pharetra at. ',
            feature1:'Feature 1 here',
            feature2:'Feature 2 here',
            feature3:'Feature 3 here',
            image: '/assets/images/services/synthetic-accent-lashes-featured.jpg',
            imageGallery: [
                {
                    key: 1,
                    image: '/assets/images/services/synthetic-accent-lashes1.jpg',
                    description: '1 Lorem ipsum dolor sit amet'
                },
                {
                    key: 2,
                    image: '/assets/images/services/synthetic-accent-lashes2.jpg',
                    description: '2 Lorem ipsum dolor sit amet'
                },
                {
                    key: 3,
                    image: '/assets/images/services/synthetic-accent-lashes3.jpg',
                    description: '3 Lorem ipsum dolor sit amet'
                },
                {
                    key: 4,
                    image: '/assets/images/services/synthetic-accent-lashes4.jpg',
                    description: '4 Lorem ipsum dolor sit amet'
                }
            ]
        },
        {
            id: 6,
            name: 'Bottom lashes',
            url: '/lash-services/specialty/bottom-lashes',
            category: 'specialty',
            price: '50',
            description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean consectetur ligula neque, et gravida libero pharetra at. ',
            feature1:'Feature 1 here',
            feature2:'Feature 2 here',
            feature3:'Feature 3 here',
            image: '/assets/images/services/bottom-lashes-featured.jpg',
            imageGallery: [
                {
                    key: 1,
                    image: '/assets/images/services/bottom-lashes1.jpg',
                    description: '1 Lorem ipsum dolor sit amet'
                },
                {
                    key: 2,
                    image: '/assets/images/services/bottom-lashes2.jpg',
                    description: '2 Lorem ipsum dolor sit amet'
                },
                {
                    key: 3,
                    image: '/assets/images/services/bottom-lashes3.jpg',
                    description: '3 Lorem ipsum dolor sit amet'
                },
                {
                    key: 4,
                    image: '/assets/images/services/bottom-lashes4.jpg',
                    description: '4 Lorem ipsum dolor sit amet'
                }
            ]
        },
        {
            id: 7,
            name: 'Removal',
            url: '/lash-services/specialty/removal',
            category: 'specialty',
            price: '50',
            description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean consectetur ligula neque, et gravida libero pharetra at. ',
            feature1:'Feature 1 here',
            feature2:'Feature 2 here',
            feature3:'Feature 3 here',
            image: '/assets/images/services/removal-featured.jpg',
            imageGallery: [
                {
                    key: 1,
                    image: '/assets/images/services/removal1.jpg',
                    description: '1 Lorem ipsum dolor sit amet'
                },
                {
                    key: 2,
                    image: '/assets/images/services/removal2.jpg',
                    description: '2 Lorem ipsum dolor sit amet'
                },
                {
                    key: 3,
                    image: '/assets/images/services/removal3.jpg',
                    description: '3 Lorem ipsum dolor sit amet'
                },
                {
                    key: 4,
                    image: '/assets/images/services/removal4.jpg',
                    description: '4 Lorem ipsum dolor sit amet'
                }
            ]
        },
        {
            id: 8,
            name: 'RevitaLash conditioner',
            url: '/lash-services/specialty/revitalash-conditioner',
            category: 'specialty',
            price: '100 - 150',
            description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean consectetur ligula neque, et gravida libero pharetra at. ',
            feature1:'Feature 1 here',
            feature2:'Feature 2 here',
            feature3:'Feature 3 here',
            image: '/assets/images/services/revitalash-conditioner-featured.jpg',
            imageGallery: [
                {
                    key: 1,
                    image: '/assets/images/services/revitalash-conditioner1.jpg',
                    description: '1 Lorem ipsum dolor sit amet'
                },
                {
                    key: 2,
                    image: '/assets/images/services/revitalash-conditioner2.jpg',
                    description: '2 Lorem ipsum dolor sit amet'
                },
                {
                    key: 3,
                    image: '/assets/images/services/revitalash-conditioner3.jpg',
                    description: '3 Lorem ipsum dolor sit amet'
                },
                {
                    key: 4,
                    image: '/assets/images/services/revitalash-conditioner4.jpg',
                    description: '4 Lorem ipsum dolor sit amet'
                }
            ]
        }

    ];