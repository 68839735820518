import React, { Component } from 'react';
import * as bootstrap from 'bootstrap'

// Prevent unused variable warnings
while (false) {
    console.log(bootstrap)
}

class AdminHeader extends Component {


    constructor(props) {
        super(props);
        this.state = {
            isNavOpen: false,
            activeAdmin: "",
            activeClients: "",
            activeAppointments: "",
            activeSchedules: ""
        }
        this.toggleNav = this.toggleNav.bind(this);
    }

    componentDidMount() {

        switch (window.location.pathname.split("/").pop()) {
            case "clients":
                this.setState({activeClients: "active"});
                break;
            case "appointments":
                this.setState({activeAppointments: "active"});
                break;
            case "schedules":
                this.setState({activeSchedules: "active"});
                break;
            default:
                this.setState({activeAdmin: "active"});
                break;
        }
    }

    toggleNav() {
        this.setState({
            isNavOpen: !this.state.isNavOpen
        });
    }


    render() {

        const logout = () => {
            localStorage.removeItem("user");
            window.location.href = '/';
        };

        return (

            <>
                <div className="site-mobile-menu site-navbar-target">
                    <div className="site-mobile-menu-header">
                        <div className="site-mobile-menu-close">
                            <span className="icofont-close js-menu-toggle"></span>
                        </div>
                    </div>
                    <div className="site-mobile-menu-body"></div>
                </div>

                <nav className="site-nav">
                    <div className="container">
                        <div className="menu-bg-wrap">
                            <div className="site-navigation">
                                <a href="/" className="logo m-0 float-start">Lashes West</a>

                                <ul className="js-clone-nav d-none d-lg-inline-block text-start site-menu float-end">
                                    <li className={this.state.activeAdmin}><a href="/admin">Admin site</a></li>
                                    <li className={this.state.activeClients}><a href="/admin/clients">Clients</a></li>
                                    <li className={this.state.activeAppointments}><a href="/admin/appointments">Appointments</a></li>
                                    <li className={this.state.activeSchedules}><a href="/admin/schedules">Schedules</a></li>

                                    <li>
                                        {localStorage.getItem("user") ?
                                            <button
                                                onClick={logout}
                                                style={{
                                                    color: "red",
                                                    border: "1px solid gray",
                                                    backgroundColor: "white",
                                                    padding: "0.5rem 1rem",
                                                    cursor: "pointer",
                                                }}
                                            >
                                                Logout
                                            </button>
                                            : <></>
                                        }
                                    </li>
                                </ul>

                                <a href="www.lasheswest.com" onClick={(e) => { e.preventDefault(); }} className="burger ml-auto float-end mt-1 site-menu-toggle js-menu-toggle d-inline-block d-lg-none" data-toggle="collapse" data-target="#main-navbar">
                                    <span></span>
                                </a>

                            </div>
                        </div>
                    </div>
                </nav>


            </>


        )
    }
}

export default AdminHeader;