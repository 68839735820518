import React, { useEffect } from "react";
import useFetch from "../../hooks/useFetch";

// https://developers.google.com/identity/gsi/web/reference/js-reference

const AdminLogin = () => {
  const { handleGoogle, loading, error } = useFetch(
    "/.netlify/functions/server/api/authentication/login"
  );

  //await fetch('/.netlify/functions/server/api/appointments');

  useEffect(() => {
    /* global google */
    if (window.google) {
      google.accounts.id.initialize({
        client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
        callback: handleGoogle,
      });

      google.accounts.id.renderButton(document.getElementById("loginDiv"), {
        // type: "standard",
        theme: "filled_black",
        // size: "small",
        text: "signin_with",
        shape: "pill",
      });

      //google.accounts.id.prompt()
    }
  }, [handleGoogle]);

  return (
    <>
      <div>
        <div className="section">
          <div className="container">
            <div className="row justify-content-center align-items-center mb-4">
              <div className="col-lg-7 text-center">
                <h1>Log In</h1>
                <p className="mt-3">Please log in with your gmail credentials by clicking the button below.</p>
              </div>
            </div>
            <div className="row justify-content-center align-items-center mb-4">
              <div className="col-lg-7 d-flex align-items-center justify-content-center">
                {loading ? <div>Loading....</div> : <div id="loginDiv"></div>}
              </div>
            </div>
            <div className="row justify-content-center align-items-center mb-4">
              <div className="col-lg-7 d-flex align-items-center justify-content-center">
                {error && <div style={{ color: "red" }}>{error}</div>}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AdminLogin;