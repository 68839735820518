import React, { Component } from 'react';
import * as bootstrap from 'bootstrap'

// Prevent unused variable warnings
while (false) {
    console.log(bootstrap)
   }

class Header extends Component {


    constructor(props) {
        super(props);
        this.state = {
            isNavOpen: false
        }
        this.toggleNav = this.toggleNav.bind(this);
    }

    toggleNav() {
        this.setState({
            isNavOpen: !this.state.isNavOpen
        });
    }


    render() {
        return (

            <>
                <div className="site-mobile-menu site-navbar-target">
                    <div className="site-mobile-menu-header">
                        <div className="site-mobile-menu-close">
                            <span className="icofont-close js-menu-toggle"></span>
                        </div>
                    </div>
                    <div className="site-mobile-menu-body"></div>
                </div>

                <nav className="site-nav">
                    <div className="container">
                        <div className="menu-bg-wrap">
                            <div className="site-navigation">
                                <a href="/" className="logo m-0 float-start">Lashes West</a>

                                <ul className="js-clone-nav d-none d-lg-inline-block text-start site-menu float-end">
                                    <li className="active"><a href="/">Home</a></li>
                                    <li className="has-children">
                                        <a href="/lash-services">Services and photos</a>
                                        <ul className="dropdown">
                                            <li><a href="/lash-services/light-set">Light Set</a></li>
                                            <li><a href="/lash-services/full-set">Full Set</a></li>
                                            <li><a href="/lash-services/fills">Fills</a></li>
                                            <li className="has-children">
                                                <a href="/lash-services/specialty">Specialty</a>
                                                <ul className="dropdown">
                                                    <li><a href="/lash-services/specialty/blend">Blend</a></li>
                                                    <li><a href="/lash-services/specialty/mega-volume">Mega Volume</a></li>
                                                    <li><a href="/lash-services/specialty/synthetic-accent-lashes">Synthetic Accent Lashes</a></li>
                                                    <li><a href="/lash-services/specialty/bottom-lashes">Bottom Lashes</a></li>
                                                    <li><a href="/lash-services/specialty/removal">Removal</a></li>
                                                    <li><a href="/lash-services/specialty/revitalash-conditioner">RevitalLash Conditioner</a></li>
                                                </ul>
                                            </li>
                                            <li><a href="/eyelash-extensions-client-photos">Photo gallery</a></li>
                                        </ul>
                                    </li>
                                    <li><a href="/appointments">Appointments</a></li>
                                    <li><a href="/location">Location</a></li>
                                    <li><a href="/online-shop">RevitaLash</a></li>
                                </ul>

                                <a href="www.lasheswest.com" onClick={(e) => { e.preventDefault(); }} className="burger ml-auto float-end mt-1 site-menu-toggle js-menu-toggle d-inline-block d-lg-none" data-toggle="collapse" data-target="#main-navbar">
                                    <span></span>
                                </a>

                            </div>
                        </div>
                    </div>
                </nav>


            </>


        )
    }
}

export default Header;