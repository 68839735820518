import React, { Component } from 'react';
import Main from './components/MainComponent';
import WebFont from 'webfontloader';
import './css/aos.css';
import './css/App.css';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { ConfigureStore } from './redux/configureStore';

const store = ConfigureStore();

WebFont.load({
  google: {
    families: ['Comfortaa:wght@400;700', 'Roboto']
  }
});

class App extends Component {

  render() {
    return (
      <Provider store={store}>
      <BrowserRouter>
        <div className="App">
          <Main />
        </div>
      </BrowserRouter>
      </Provider>
    );

  }
}

export default App;









