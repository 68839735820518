import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { Button } from 'reactstrap';
import { Table, Smart } from 'smart-webcomponents-react/table';

const AdminClients = () => {

    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [phone, setPhone] = useState('');
    const [email, setEmail] = useState('');

    const [clients, setClients] = useState([]);

    const navigate = useNavigate();

    const handleClientChange = control => (event) => {

        switch (control) {
            case "firstName":
                setFirstName(event.target.value);
                break;
            case "lastName":
                setLastName(event.target.value);
                break;
            case "phone":
                setPhone(event.target.value);
                break;
            case "email":
                setEmail(event.target.value);
                break;
            default:
                break;
        }

    };

    const handleClientsClick = () => {
        navigate(`/admin/clients?firstname=${firstName}&lastname=${lastName}&phone=${phone}&email=${email}`);
    };

    const handleCellClick = (event) => {
            console.log(event.detail.row.Cid);
            navigate(`/admin/clientdetail/${event.detail.row.Cid}`);

    }


    useEffect(() => {

        let tempFirst = '';
        let tempLast = '';
        let tempPhone = '';
        let tempEmail = '';

        const urlSearchParams = new URLSearchParams(window.location.search);
        if (urlSearchParams.has('firstname')) {
            tempFirst = urlSearchParams.get('firstname');
            setFirstName(tempFirst);
        }
        if (urlSearchParams.has('lastname')) {
            tempLast = urlSearchParams.get('lastname');
            setLastName(tempLast);
        }
        if (urlSearchParams.has('phone')) {
            tempPhone = urlSearchParams.get('phone');
            setPhone(tempPhone);
        }
        if (urlSearchParams.has('email')) {
            tempEmail = urlSearchParams.get('email');
            setEmail(tempEmail);
        }

        if (urlSearchParams.has('firstname') || urlSearchParams.has('lastname') || urlSearchParams.has('phone') || urlSearchParams.has('email')) {
            //We must use the temp variables here because the state constants won't be set until the next refresh
            const params = `firstname=${tempFirst}&lastname=${tempLast}&phone=${tempPhone}&email=${tempEmail}`;
            callGetClientsApi(params)
                .then(
                    res => {
                        if(typeof res !== 'undefined' && typeof res.clients !== 'undefined' && res.clients.length > 0){
                            setClients(res.clients);
                        }
                        else{
                            setClients([]);
                        }
                    }
                )
                .catch(err => console.log(err));
        }



    }, []);


    const callGetClientsApi = async (queryParams) => {
        const response = await fetch(`/.netlify/functions/server/api/clients?${queryParams}`);
        const body = await response.json();
        if (response.status !== 200) throw Error(body.message);
        return body;
    }


    const dataSourceSettings = {
        dataFields: [
            'cid: int',
            'firstName: string',
            'lastName: string',
            'appointment: date',
            'phone: string',
            'email: string',
            'address1: string'
        ]
    }

    const editing = true;
    const keyboardNavigation = true;
    const columnMenu = true;
    const filtering = true;
    const paging = true;

    const sortMode = 'one';
    const columns = [
        {
            label: 'client id',
            dataField: 'Cid',
            dataType: 'int',
            allowEdit: false,
            visible: false
        },
       {
            label: 'First name',
            dataField: 'FirstName',
            dataType: 'string',
            allowEdit: false,
            width: 100,
            responsivePriority: 1
        },
        {
            label: 'Last Name',
            dataField: 'LastName',
            dataType: 'string',
            allowEdit: false,
            width: 100,
            responsivePriority: 1
        },
        {
            label: 'Next appointment',
            dataField: 'Appointment',
            formatFunction: function (settings) {
                if (!settings.value) return;
                const formatedDate = new Smart.Utilities.DateTime(settings.value).toString('dddd M/d/y H:mm tt');
                settings.value = formatedDate;
            },
            allowEdit: false,
            width: 180,
            responsivePriority: 1
        },
        {
            label: 'Phone',
            dataField: 'Phone',
            dataType: 'string',
            allowEdit: false,
            width: 100,
            responsivePriority: 1
        },
        {
            label: 'Email',
            dataField: 'Email',
            dataType: 'string',
            allowEdit: false,
            width: 100,
            responsivePriority: 2
        },
        {
            label: 'Address',
            dataField: 'Address1',
            dataType: 'string',
            allowEdit: false,
            width: 300,
            responsivePriority: 2

        }];



    return (
        <div>
            <div className="section">
                <div className="container">

                    <div className="row">
                        <div className="col-12 border border-2 rounded-3">

                            <div className="row justify-content-center align-items-center mb-4 ">
                                <div className="col-lg-7 text-center">
                                    <h1>Clients</h1>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12 col-md-10 mb-1">
                                    <div className="row">
                                        <div className="col-12 col-md-3 mb-1">
                                            <div className="row">
                                                <div className="col-5 d-flex align-items-center justify-content-end">
                                                    <label htmlFor="inputFirstName" className="ps-3 fw-bold mb-1">First name:</label><br className="d-none d-md-inline" />
                                                </div>
                                                <div className="col-7">
                                                    <input type="text" id="inputFirstName" name="inputFirstName" onChange={handleClientChange("firstName")} value={firstName} className="form-control form-control-admin"></input>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-3 mb-1">
                                            <div className="row">
                                                <div className="col-5 d-flex align-items-center justify-content-end">
                                                    <label htmlFor="inputLastName" className="ps-3 fw-bold mb-1">Last name:</label><br className="d-none d-md-inline" />
                                                </div>
                                                <div className="col-7">
                                                    <input type="text" id="inputLastName" name="inputLastName" onChange={handleClientChange("lastName")} value={lastName} className="form-control form-control-admin"></input>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-3 mb-1">
                                            <div className="row">
                                                <div className="col-5 d-flex align-items-center justify-content-end">
                                                    <label htmlFor="inputPhone" className="ps-3 fw-bold mb-1">Phone:</label><br className="d-none d-md-inline" />
                                                </div>
                                                <div className="col-7">
                                                    <input type="text" id="inputPhone" name="inputPhone" onChange={handleClientChange("phone")} value={phone} className="form-control form-control-admin"></input>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-3 mb-1">
                                            <div className="row">
                                                <div className="col-5 d-flex align-items-center justify-content-end">
                                                    <label htmlFor="inputEmail" className="ps-3 fw-bold mb-1">Email:</label><br className="d-none d-md-inline" />
                                                </div>
                                                <div className="col-7">
                                                    <input type="text" id="inputEmail" name="inputEmail" onChange={handleClientChange("email")} value={email} className="form-control form-control-admin"></input>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-md-2 d-flex align-items-center justify-content-center mb-1">
                                    <Button onClick={handleClientsClick} className="btn btn-primary bg-primary text-white">
                                        Search
                                    </Button>
                                </div>
                            </div>

                        </div>
                    </div>


                    <div className="row mt-3">
                        <div className="col-12">
                            <Table id="table"
                                dataSource={clients}
                                dataSourceSettings={dataSourceSettings}
                                editing={editing}
                                keyboardNavigation={keyboardNavigation}
                                columnMenu={columnMenu}
                                filtering={filtering}
                                sortMode={sortMode}
                                columns={columns}
                                paging={paging}
                                className="table-hover table-striped thead-primary"
                                onCellClick={handleCellClick} >

                            </Table>
                        </div>
                    </div>


                </div>
            </div>
        </div>
    );
};

export default AdminClients;