import React from 'react';

class AdminSchedules extends React.Component {



    componentDidMount() {
    }

    render() {
        return (
            <div>
                <div className="section">
                    <div className="container">
                            <div className="row justify-content-center align-items-center mb-4">
                                <div className="col-lg-7 text-center">
                                    <h1>Schedules</h1>
                                </div>
                            </div>

                    </div>
                </div>

            </div>
        );
    }
}

export default AdminSchedules;