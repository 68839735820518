import React from "react";
import { Row, Col, Label, Button } from 'reactstrap';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import MaskedInput from "react-text-mask";
import { Calendar } from 'smart-webcomponents-react/calendar';
import 'smart-webcomponents-react/source/styles/smart.default.css';

const phoneNumberMask = [
    "(",
    /[1-9]/,
    /\d/,
    /\d/,
    ")",
    " ",
    /\d/,
    /\d/,
    /\d/,
    "-",
    /\d/,
    /\d/,
    /\d/,
    /\d/
];

const phoneRegExp = /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/

const AppointmentSchema = Yup.object().shape({
    serviceType: Yup
        .number()
        .positive('Select a service from the list'),
    firstName: Yup.string()
        .min(2, 'First name must be two or more characters')
        .max(50, 'First name must be less than 50 characters')
        .required('Required'),
    lastName: Yup.string()
        .min(2, 'Last name must be two or more characters')
        .max(50, 'Last name must be less than 50 characters')
        .required('Required'),
    email1: Yup.string()
        .email('Invalid email'),
    phone1: Yup.string()
        .required('Required')
        .matches(phoneRegExp, 'Enter a 10-digit phone number'),
});

class AppointmentBooking extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            availableServices: [],
            importantDatesData: [],
            importantDates: [],
            openingDates: [],
            daveTest: "NOT SET",
            showBooking: this.props.bookingTime !== undefined && this.props.bookingTime > 0,
            selectedBooking: null,
            appointmentId: 0,
            loading: false
        };

        this.calendar = React.createRef();
        this.window = React.createRef();
        this.multilinetextbox = React.createRef();
        this.daterangeinput = React.createRef();
        this.openingDetails = null;
    }

    //Handles Openings Data
    getImportantDates(dataSource) {
        let dates = [];

        if (!dataSource) {
            return dates;
        }

        for (let i = 0; i < dataSource.length; i++) {

            var exists = false;

            for (let j = 0; j < dates.length; j++) {
                if (dataSource[i].openingDate.getTime() === dates[j].date.getTime()) {
                    exists = true;
                    break;
                }
            }

            if (!exists) {
                dates.push({
                    date: new Date(dataSource[i].openingDate),
                    description: "DESCRIPTION1" //dataSource[i].description
                });

            }
        }

        return dates;
    }

    //Returns an opening based on it's Date
    getImportantDate(date) {
        date = new Date(date);
        date.setHours(0, 0, 0, 0);
        const opening = this.state.importantDatesData.find(dateObj => dateObj.date.getTime() === date.getTime());
        if (!opening) {
            return;
        }
        const openingDates = this.state.importantDatesData.filter(dateObj => dateObj.description === opening.description);
        if (openingDates.length) {
            return {
                from: openingDates[0].date,
                to: openingDates[openingDates.length - 1].date,
                description: "TEST" //this.getToolTipMarkup() // opening.description
            };
        }
    }

    weekday = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
    month = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

    months = 1;

    scrollButtonsPosition = 'both';

    tooltip = true;

    //Handle Calendar Header buttons
    handleCalendarClick(opening) {
        const calendar = this.calendar.current,
            openingWindow = this.window.current;
        let target = opening.target;

        if (target.closest('.opening-window-button')) {
            openingWindow.open();
        }

        target = target.closest('smart-button');

        if (!target) {
            return;
        }

        switch (target.id) {
            case 'next':
                calendar.navigate(12);
                break;
            case 'previous':
                calendar.navigate(-12);
                break;
            case 'month':
                calendar.displayMode = 'month';
                break;
            case 'year':
                calendar.displayMode = 'year';
                break;
            case 'decade':
                calendar.displayMode = 'decade';
                break;
            default:
                const today = new Date();
                today.setDate(1);
                today.setMonth(0);
                calendar.navigate(today);
                break;
        }
    }

    getToolTipMarkup(openings) {

        let markup = '<div class="appointmentLinkContainer">';

        const bookingDay = new Date(openings[0].openingTime);
        // markup += `<div class="float-right text-end"><button type="button" class="close" onClick=${this.handleCalendarClose()}><span aria-hidden="true">×</span><span class="sr-only">Close</span></button></div>`;
        markup += `<div class="appointmentLinkContainerText">${this.weekday[bookingDay.getDay()]}, ${this.month[bookingDay.getMonth()]} ${bookingDay.getDate()}, ${bookingDay.getFullYear()}</div>`;
        markup += `<div>Click to make an appointment on one of the available times below.</div>`

        openings.map(function (value) {
            const bookingTime = new Date(value.openingTime).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
            return markup += `<div class="m-2 btn btn-sm btn-appointment"><a href="/appointments/${value.openingNumeric}">${bookingTime}</a></div>`;
        });

        markup += '</div>';

        return markup;
    }



    //Handle Tooltip and prepare editor window
    handleCalendarOpen(opening) {
        const tooltip = opening.detail.target;

        if (!(tooltip instanceof window.Smart.Tooltip)) {
            return;
        }

        this.openingDetails = this.getImportantDate(opening.detail.value);

        const dateOpeningDetails = this.state.openingDates.filter(dt => dt.openingDate.getTime() === opening.detail.value.getTime());

        if (this.openingDetails) {
            tooltip.value = this.getToolTipMarkup(dateOpeningDetails);
        }
    }


    //Set the primary button for the current display mode
    handleDisplayModeChange() {
        const calendar = this.calendar.current;
        const displayMode = calendar.displayMode,
            viewSelection = document.querySelector('.view-selection'),
            viewSelectionButtons = viewSelection.querySelectorAll('smart-button');

        for (let i = 0; i < viewSelectionButtons.length; i++) {
            const button = viewSelectionButtons[i];

            if (button.id !== displayMode) {
                button.classList.remove('primary');
            }
            else {
                button.classList.add('primary');
            }
        }
    }


    componentDidMount() {

        if (!this.state.showBooking) {
            const calendar = this.calendar.current

            const templateContainer = document.getElementById('templates');

            templateContainer.innerHTML = `
                    <template id="tooltipTemplate">
                        <div class="tooltip-content">
                        {{description}}
                        </div>
                    </template>
            `;

            calendar.tooltipTemplate = 'tooltipTemplate';
        }

        this.callServicesApi()
            .then(res => this.setState({ availableServices: res.serviceNames }))
            .catch(err => console.log(err));

        this.setState({ isLoading: true });
        this.callAppointmentsApi()
            .then(
                res => {
                    // this.setState({ openings: res.openings });
                    if (res.openings.length > 0) {
                        var tempOpenings = res.openings;
                        //Build an array of dates that are available for appointments
                        var tempBuildOpeningDates = [];
                        var tempImportantDatesData = [];
                        tempOpenings.map(function (value) {
                            let dateConverted = new Date(value.AppointmentDateTime);
                            dateConverted.setHours(0, 0, 0, 0);
                            const addDate = { openingDate: dateConverted, openingTime: value.AppointmentDateTime, openingNumeric: new Date(value.AppointmentDateTime).getTime() };
                            return tempBuildOpeningDates.push(addDate);
                        });
                        this.setState({ openingDates: tempBuildOpeningDates });

                        tempImportantDatesData = this.getImportantDates(tempBuildOpeningDates);

                        this.setState({ importantDatesData: tempImportantDatesData });
                        this.setState({ importantDates: tempImportantDatesData.map((dateObj) => dateObj.date) });

                    }
                    this.setState({ isLoading: false });

                }
            )
            .catch(err => console.log(err));


    }

    callServicesApi = async () => {
        const response = await fetch('/.netlify/functions/server/api/services');
        const body = await response.json();
        if (response.status !== 200) throw Error(body.message);
        return body;
    };

    callAppointmentsApi = async () => {
        const response = await fetch('/.netlify/functions/server/api/appointments');
        const body = await response.json();
        if (response.status !== 200) throw Error(body.message);
        return body;
    };

    postBookAppointment = async (values) => {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(values)
        };

        const response = await fetch('/.netlify/functions/server/api/appointments', requestOptions);
        const body = await response.json();
        if (response.status !== 200) throw Error(body.message);
        return body;

    }

    render() {

        const AppointmentWithBookingTime = () => {


            if (this.state.showBooking) {

                const bookingDay = new Date(parseInt(this.props.bookingTime));
                const markupDay = `${this.weekday[bookingDay.getDay()]}, ${this.month[bookingDay.getMonth()]} ${bookingDay.getDate()}, ${bookingDay.getFullYear()}`;
                const bookingTime = bookingDay.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });


                if (this.state.appointmentId === 0) {
                    return (

                        <div>

                            <div className="appointmentLinkContainerText">{markupDay}</div>
                            <div className="appointmentLinkContainerTextStrong">{bookingTime}</div>

                            <Formik
                                initialValues={{
                                    appointmentTime: bookingDay,
                                    serviceType: '0',
                                    firstName: '',
                                    lastName: '',
                                    phone1: '',
                                    email1: '',
                                }}
                                validationSchema={AppointmentSchema}
                                onSubmit={values => {

                                    this.postBookAppointment(values)
                                        .then(res => {
                                            this.setState({ appointmentId: res.appointmentId });
                                            window.scrollTo(0, 0);
                                        })
                                        .catch(err => console.log(err));
                                }}
                            >
                                {({ errors, touched, handleChange }) => (
                                    <Form>
                                        <Row className="form-group m-2">
                                            <Label htmlFor="serviceType" md={4}>Service:</Label>
                                            <Col md={8}>
                                                <Field as="select"
                                                    name="serviceType"
                                                    id="serviceType"
                                                    className="form-control"
                                                >
                                                    <option value="0">Select a service</option>
                                                    {this.state.availableServices.map((srv) => <option key={srv.ServiceName} value={srv.ServiceID}>{srv.ServiceName}</option>)}
                                                </Field>
                                                {errors.serviceType && touched.serviceType ? (
                                                    <div className="error">{errors.serviceType}</div>
                                                ) : null}
                                            </Col>
                                        </Row>
                                        <Row className="form-group m-2">
                                            <Label htmlFor="firstname" md={4}>First name:</Label>
                                            <Col md={8}>
                                                <Field name="firstName"
                                                    placeholder="First name"
                                                    className="form-control"
                                                />
                                                {errors.firstName && touched.firstName ? (
                                                    <div className="error">{errors.firstName}</div>
                                                ) : null}
                                            </Col>
                                        </Row>
                                        <Row className="form-group m-2">
                                            <Label htmlFor="lastname" md={4}>Last name:</Label>
                                            <Col md={8}>
                                                <Field name="lastName"
                                                    placeholder="Last name"
                                                    className="form-control"
                                                />
                                                {errors.lastName && touched.lastName ? (
                                                    <div className="error">{errors.lastName}</div>
                                                ) : null}
                                            </Col>
                                        </Row>
                                        <Row className="form-group m-2">
                                            <Label htmlFor="phone1" md={4}>Phone:</Label>
                                            <Col md={8}>

                                                <Field
                                                    name="phone1">
                                                    {({ field }) => (
                                                        <MaskedInput
                                                            {...field}
                                                            mask={phoneNumberMask}
                                                            id="phone1"
                                                            placeholder="Phone"
                                                            type="text"
                                                            className={
                                                                errors.phone1 && touched.phone1
                                                                    ? "form-control error"
                                                                    : "form-control"
                                                            }
                                                        />
                                                    )}
                                                </Field>

                                                {errors.phone1 && touched.phone1 && (
                                                    <div className="error">{errors.phone1}</div>
                                                )}

                                            </Col>
                                        </Row>
                                        <Row className="form-group m-2">
                                            <Label htmlFor="email1" md={4}>Email (optional):</Label>
                                            <Col md={8}>
                                                <Field name="email1"
                                                    type="email"
                                                    placeholder="Email"
                                                    className="form-control"
                                                />
                                                {errors.email1 && touched.email1 ? <div className="error">{errors.email1}</div> : null}
                                            </Col>
                                        </Row>
                                        <Row className="form-group mt-5">
                                            <Col xs={8} className="justify-content-end">
                                                <Button type="submit" className="btn btn-primary bg-primary text-white float-end">
                                                    Complete appointment
                                                </Button>
                                            </Col>
                                            <Col xs={4} className="justify-content-start text-start">
                                                <a href="/appointments"><Button type="button" className="btn btn-primary bg-primary text-white">Change</Button></a>
                                            </Col>
                                        </Row>
                                    </Form>
                                )}
                            </Formik>
                        </div>

                    )

                } else if (this.state.appointmentId > 0) {
                    return (
                        <div>
                            <h5>Your appointment has been booked!</h5>

                            <div className="appointmentLinkContainerText">{markupDay}</div>
                            <div className="appointmentLinkContainerTextStrong">{bookingTime}</div>

                        </div>
                    )

                } else if (this.state.appointmentId === -1) {
                    return (
                        <div>
                            <h5 className="error">We're sorry, this appointment time is no longer available:</h5>

                            <div className="appointmentLinkContainerText">{markupDay}</div>
                            <div className="appointmentLinkContainerTextStrong">{bookingTime}</div>
                            <div className="m-3">
                                Please select a different time below or call/text me at 612-327-7431 to book your appointment.
                            </div>
                            <div className="m-5">
                                <a href="/appointments"><Button type="button" className="btn btn-primary bg-primary text-white">Select a different appointment time</Button></a>
                            </div>

                        </div>
                    )
                } else {
                    return (
                        <div>
                            <h5 className="error">We're sorry, an error has occurred when trying to book your appointment.</h5>

                            <div className="m-3">
                                Please try again below or call/text me at 612-327-7431 to book your appointment.
                            </div>
                            <div className="m-5">
                                <a href="/appointments"><Button type="button" className="btn btn-primary bg-primary text-white">Try again</Button></a>
                            </div>

                        </div>
                    )
                }

            } else {

                return (
                    <div>
                        {this.state.isLoading ?
                            <div className="d-flex align-items-center justify-content-center text-center mb-2">
                                <span className="pe-3"><strong>Loading openings...</strong></span>
                                <div className="spinner-border ml-auto text-primary" role="status" aria-hidden="true"></div>
                            </div>
                            : <></>}
                        <Calendar ref={this.calendar} id="calendar"
                            importantDates={this.state.importantDates}
                            months={this.months}
                            scrollButtonsPosition={this.scrollButtonsPosition}
                            tooltip={this.tooltip}
                            onOpen={this.handleCalendarOpen.bind(this)}
                            onDisplayModeChange={this.handleDisplayModeChange.bind(this)}></Calendar>
                    </div>
                )
            }
        }

        return (
            <div>
                <AppointmentWithBookingTime></AppointmentWithBookingTime>
                <div id="templates"></div>
            </div>
        );
    }
}

export default AppointmentBooking;