import React, { Component } from 'react';
import smoothscroll from 'smoothscroll-polyfill';
import {
    isBrowser,
    isMobile
} from "react-device-detect";

class ServiceDetails extends Component {

    constructor(props) {
        super(props);
        smoothscroll.polyfill();
        this.state = {
            showScrollButtonLeft: true,
            showScrollButtonRight: true,
            scrollIndex: 0
        };
        this.serviceItemsDiv = React.createRef();
    }

    componentDidMount() {

        var container = document.getElementById('service-item-container');
        var serviceItem = document.getElementById('service-item5');

        if (this.props.service.id > 0) {
            var scrollIndex = this.state.scrollIndex;
            var scrollAmount;
            if (isMobile) {
                scrollIndex = this.props.service.id;
            } else {
                scrollIndex = this.props.service.id - 1;
                if (this.props.service.id === 1) {
                    this.setState({ showScrollButtonLeft: false });
                }
            }
            scrollAmount = serviceItem.clientWidth * scrollIndex;
            container.scrollBy({ top: 0, left: scrollAmount, behavior: 'smooth' });
            this.setState({ scrollIndex: scrollIndex })

            var maxId = this.props.services.reduce((a, service) => a = a > service.id ? a : service.id, 0);
            if (isBrowser) {
                maxId -= 1;
            }

            if (this.props.service.id >= maxId) {
                this.setState({ showScrollButtonRight: false });
            }
        } else {
            this.setState({ showScrollButtonLeft: false });
        }


    }

    scroll = ({ direction }) => {
        var container = document.getElementById('service-item-container');
        var serviceItem = document.getElementById('service-item3');
        var scrollIndex = this.state.scrollIndex;

        var scrollMax;

        if (isMobile) {
            scrollMax = this.props.services.length - 1;
        } else {
            scrollMax = this.props.services.length - 2;
        }

        if (direction === 'right' && scrollIndex < scrollMax) {
            container.scrollBy({ top: 0, left: serviceItem.clientWidth, behavior: 'smooth' });
            scrollIndex += 1;
        } else if (direction === 'left' && scrollIndex > 0) {
            container.scrollBy({ top: 0, left: serviceItem.clientWidth * -1, behavior: 'smooth' });
            scrollIndex -= 1;
        }

        this.setState({ showScrollButtonLeft: scrollIndex > 0 || (scrollIndex === 1 && isMobile) });
        this.setState({ showScrollButtonRight: scrollIndex < scrollMax });
        this.setState({ scrollIndex: scrollIndex });

    };


    render() {

        var itemCount = 0;

        function RenderServiceItem({ service, classes, buttonClasses, propsServiceId }) {
            return (
                <div className={classes}>
                    <div className="body">
                        <div className={service.price ? "price service-item-title" : "price service-item-title-short"} >
                            <a href={service.url}>
                                <span className="d-block plan text-secondary">{service.name}</span>
                            </a>
                            {service.price ?
                                <div className="price-amount">
                                    <span className="price"><sup>$</sup><span>{service.price}</span></span>
                                </div>
                                : null}
                        </div>
                        <div className={service.pricing ? null : "service-item-features-container"}>
                            {service.description2 ? <p className='mb-3 text-center'>{service.description2}</p> : null}
                            {service.description3 ? <p className='mb-3 text-center'>{service.description3}</p> : null}
                            {service.price ?
                                <ul className="list-unstyled ul-check primary mb-5 service-item-features">
                                    <li>{service.feature1}</li>
                                    <li>{service.feature2}</li>
                                    <li>{service.feature3}</li>
                                </ul>
                                :
                                <ul className="list-unstyled primary mt-2">
                                    <li className="service-item-features-with-pricing">{service.feature1}<span className="price-short"> <sup>$</sup><span>{service.feature1price}</span></span></li>
                                    <li className='mb-2'>{service.feature1description}</li>
                                    {service.feature2 ? <li className="service-item-features-with-pricing">{service.feature2}<span className="price-short"> <sup>$</sup><span>{service.feature2price}</span></span></li> : null}
                                    {service.feature2 ? <li className='mb-2'>{service.feature2description}</li> : null}
                                    {service.feature3 ? <li className="service-item-features-with-pricing">{service.feature3}<span className="price-short"> <sup>$</sup><span>{service.feature3price}</span></span></li> : null}
                                    {service.feature3 ? <li className='mb-2'>{service.feature3description}</li> : null}
                                    {service.feature4 ? <li className="service-item-features-with-pricing">{service.feature4}<span className="price-short"> <sup>$</sup><span>{service.feature4price}</span></span></li> : null}
                                    {service.feature4 ? <li>{service.feature4description}</li> : null}
                                </ul>
                            }
                            {service.description4 ? <p>{service.description4}</p> : null}
                            {service.description5 ? <p>{service.description5}</p> : null}
                        </div>
                        <p className={service.id === propsServiceId ? "text-center mt-5" : "text-center mb-2"}><a href="/appointments" className={buttonClasses}>Book online</a></p>
                        <MoreInfoButton service={service} propsServiceId={propsServiceId}></MoreInfoButton>
                    </div>
                </div>
            );
        }

        function MoreInfoButton({ service, propsServiceId }) {

            if (service.id !== propsServiceId) {
                return (
                    <p className="text-center mb-2"><a className="btn btn-outline-primary" href={service.url}>More info</a></p>
                );
            } else {
                return (
                    <p className="service-item-empty"></p>
                );
            }

        };

        function ServiceItemFiller({ props, location }) {

            const maxId = props.services.reduce((a, service) => a = a > service.id ? a : service.id, 0);

            if (isBrowser && ((props.service.id === 0 && location === 'start') || (props.service.id === maxId && location === 'end'))) {
                return (
                    <div className="col-12 col-md-6 col-lg-4">
                    </div>
                );
            } else {
                return (
                    <div></div>
                );
            }
        };

        const ScrollButton = ({ direction }) => {
            return (
                <>
                    {(direction === 'left' && this.state.showScrollButtonLeft) || (direction === 'right' && this.state.showScrollButtonRight) ? <ScrollButtonResult direction={direction} /> : null}
                </>
            )
        }

        const ScrollButtonResult = ({ direction }) => {

            var classes = "fa fa-chevron-" + direction + " fa-3x";

            return (
                <a href="/" onClick={(e) => { this.scroll({ direction }); e.preventDefault(); }}><i className={classes}></i></a>
            )
        }


        const serviceContainers = this.props.services.map((service) => {

            var classes = "pricing";
            var buttonClasses = "btn btn-outline-primary";
            if (service.id === this.props.service.id) {
                classes = "pricing active";
                buttonClasses = "btn btn-primary text-white";
            }

            itemCount += 1;
            var delay = 100 * itemCount;

            var divId = "service-item" + service.id;

            return (
                <div id={divId} key={service.id} className="col-12 col-md-6 col-lg-4" data-aos="fade-up" data-aos-delay={delay}>
                    <RenderServiceItem service={service} classes={classes} buttonClasses={buttonClasses} propsServiceId={this.props.service.id}></RenderServiceItem>
                </div>
            )

        });

        const gallery = this.props.service.imageGallery.map((image) => {

            return (
                <div className="item">
                    <div className="untree_co-testimonial text-center mb-5">
                        <div className="mb-xsmall">
                            <img key={image.key} src={image.image} alt={image.name} className="img-fluid mx-auto"></img>
                        </div>
                        <blockquote>
                            <p>{image.description}</p>
                        </blockquote>
                    </div>
                </div>

            )
        });

        return (
            <div>
                <div className="section">
                    <div className="container">
                        <div className="row justify-content-center align-items-center">
                            <div className="col-lg-7 text-center">
                                <h1 className="heading mb-2" data-aos="fade-up">{this.props.service.name}</h1>
                                <p className="w-75 mb-4 mx-auto" data-aos="fade-up" data-aos-delay="100">{this.props.service.description}</p>
                                <p><a href="/appointments" className="btn btn-primary text-white" data-aos="fade-up" data-aos-delay="200">Online appointments</a></p>
                                <img src={this.props.service.image} alt={this.props.service.name} className="img-fluid mx-auto"></img>

                            </div>
                        </div>
                    </div>
                </div>
                <div className="section bg-light">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-2 col-md-1 d-flex justify-content-center align-items-center">
                                <ScrollButton direction='left'></ScrollButton>
                            </div>
                            <div className="col-8 col-md-10">
                                <div id="service-item-container" className="scroll-container" ref={this.serviceItemsDiv}>
                                    <div className="scroll-items">
                                        <ServiceItemFiller props={this.props} location='start'></ServiceItemFiller>
                                        {serviceContainers}
                                        <ServiceItemFiller props={this.props} location='end'></ServiceItemFiller>
                                    </div>
                                </div>
                            </div> {/* /.col-10  */}
                            <div className="col-2 col-md-1 d-flex justify-content-center align-items-center">
                                <ScrollButton direction='right'></ScrollButton>
                            </div>
                        </div> {/* /.row */}
                    </div>
                </div>

                <div className="section">
                    <div id="gallery" className="container">
                        <div className="row justify-content-center mb-5">
                            <div className="col-lg-7 text-center">
                                <span className="subheading">{this.props.service.name}</span>
                                <h3 className="heading mb-4">Photo Gallery</h3>
                            </div>
                        </div>

                        <div className="row justify-content-center">
                            <div className="col-lg-8">
                                <div className="testimonial-slider-wrap">
                                    <div className="testimonial-slider" id="testimonial-slider">
                                        {gallery}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        );
    }
}

export default ServiceDetails;
