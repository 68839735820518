import React from 'react';
import { Form, Row, Col, Label, Button, FormGroup } from 'reactstrap';
import { Formik, Field } from 'formik';
import MaskedInput from "react-text-mask";
import * as Yup from 'yup';
var UsaStates = require('usa-states').UsaStates;

class AdminClientDetail extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            client: {},
            usStates: [],
            phoneTypes: [{ PhoneTypeID: 1, PhoneType: "Mobile" }, { PhoneTypeID: 2, PhoneType: "Home" }],
            phoneNumberMask: [
                "(",
                /[1-9]/,
                /\d/,
                /\d/,
                ")",
                " ",
                /\d/,
                /\d/,
                /\d/,
                "-",
                /\d/,
                /\d/,
                /\d/,
                /\d/
            ],
            phoneRegExp: /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/

        };

    }

   




    componentDidMount() {

        this.callGetClientApi()
            .then(res => {
                console.log(res.client);
                this.setState({ client: res.client });
            })
            .catch(err => console.log(err));

        var usStates = new UsaStates();
        var statesAbbreviation = usStates.arrayOf('abbreviations');
        this.setState({ usStates: statesAbbreviation });

    }

    callGetClientApi = async () => {
        const response = await fetch(`/.netlify/functions/server/api/clients/${this.props.cid}`);
        const body = await response.json();
        if (response.status !== 200) throw Error(body.message);
        return body;
    }


    clientSchema = Yup.object().shape({
        serviceType: Yup
            .number()
            .positive('Select a service from the list'),
        firstName: Yup.string()
            .min(2, 'First name must be two or more characters')
            .max(50, 'First name must be less than 50 characters')
            .required('Required'),
        lastName: Yup.string()
            .min(2, 'Last name must be two or more characters')
            .max(50, 'Last name must be less than 50 characters')
            .required('Required'),
        email1: Yup.string()
            .email('Invalid email'),
        phone1: Yup.string()
            .required('Required')
            .matches(this.phoneRegExp, 'Enter a 10-digit phone number'),
    });


    render() {



        return (
            <div>
                <div className="section">
                    <div className="container">


                        <div className="row">
                            <div className="col-12">

                                <Formik
                                    enableReinitialize
                                    initialValues={{
                                        PersonID: this.state.client.PersonID,
                                        FirstName: this.state.client.FirstName ?? "",
                                        MiddleName: this.state.client.MiddleName ?? "",
                                        LastName: this.state.client.LastName ?? "",
                                        Address1: this.state.client.Address1 ?? "",
                                        Address2: this.state.client.Address2 ?? "",
                                        City: this.state.client.City ?? "",
                                        State: this.state.client.State ?? "",
                                        ZipCode: this.state.client.ZipCode ?? "",
                                        Phone1: "0000000000",
                                        Phone1TypeID: this.state.client.Phone1TypeID ?? 0,
                                        Phone2: this.state.client.Phone2 ?? "",
                                        Phone2TypeID: this.state.client.Phone2TypeID ?? 0,
                                        Email1: this.state.client.Email1 ?? "",
                                        Email2: this.state.client.Email2 ?? "",
                                        Circumstances: this.state.client.Circumstances,
                                        Comments: this.state.client.Comments,
                                        InitialContactDate: this.state.client.InitialContactDate,
                                        Referral: this.state.client.Referral
                                    }}
                                    validationSchema={this.clientSchema}
                                    onSubmit={values => {
                                        // same shape as initial values
                                        console.log(values);

                                        this.postSaveClient(values)
                                            .then(res => {
                                                this.setState({ appointmentId: res.appointmentId });
                                                window.scrollTo(0, 0);
                                            })
                                            .catch(err => console.log(err));
                                    }}
                                >
                                    {({ errors, touched, handleChange }) => (
                                        <Form>
                                            <Row className="form-group m-1">
                                                <Col md={2} className="d-flex align-items-center justify-content-sm-start justify-content-md-end">
                                                    <Label htmlFor="FirstName">Name:</Label>
                                                </Col>
                                                <Col md={4}>
                                                    <FormGroup>
                                                        <Field name="FirstName"
                                                            placeholder="First name"
                                                            className="form-control form-control-admin"
                                                        />
                                                        {errors.FirstName && touched.FirstName ? (
                                                            <div className="error">{errors.FirstName}</div>
                                                        ) : null}

                                                    </FormGroup>
                                                </Col>
                                                <Col md={2}>
                                                    <FormGroup>
                                                        <Field name="MiddleName"
                                                            placeholder="MI"
                                                            className="form-control form-control-admin"
                                                        />
                                                        {errors.MiddleName && touched.MiddleName ? (
                                                            <div className="error">{errors.MiddleName}</div>
                                                        ) : null}
                                                    </FormGroup>
                                                </Col>
                                                <Col md={4}>
                                                    <FormGroup>
                                                        <Field name="LastName"
                                                            placeholder="Last name"
                                                            className="form-control form-control-admin"
                                                        />
                                                        {errors.LastName && touched.LastName ? (
                                                            <div className="error">{errors.LastName}</div>
                                                        ) : null}
                                                    </FormGroup>
                                                </Col>
                                            </Row>

                                            <Row className="form-group m-1">
                                                <Col md={2} className="d-flex align-items-center justify-content-sm-start justify-content-md-end">
                                                    <Label htmlFor="Address1">Address:</Label>
                                                </Col>
                                                <Col md={6}>
                                                    <FormGroup>
                                                        <Field name="Address1"
                                                            placeholder="Street address"
                                                            className="form-control form-control-admin"
                                                        />
                                                        {errors.Address1 && touched.Address1 ? (
                                                            <div className="error">{errors.Address1}</div>
                                                        ) : null}
                                                    </FormGroup>
                                                </Col>
                                                <Col md={4}>
                                                    <FormGroup>
                                                        <Field name="Address2"
                                                            placeholder="Address 2"
                                                            className="form-control form-control-admin"
                                                        />
                                                        {errors.Address2 && touched.Address2 ? (
                                                            <div className="error">{errors.Address2}</div>
                                                        ) : null}
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <Row className="form-group m-1">
                                                <Col md={2} className="d-flex align-items-center justify-content-sm-start justify-content-md-end">
                                                    <Label htmlFor="City">City/State/Zip:</Label>
                                                </Col>
                                                <Col md={4}>
                                                    <FormGroup>
                                                        <Field name="City"
                                                            placeholder="City"
                                                            className="form-control form-control-admin"
                                                        />
                                                        {errors.City && touched.City ? (
                                                            <div className="error">{errors.City}</div>
                                                        ) : null}
                                                    </FormGroup>
                                                </Col>
                                                <Col md={2}>
                                                    <FormGroup>
                                                        <Field as="select"
                                                            name="StateAbbreviation"
                                                            id="StateAbbreviation"
                                                            className="form-control form-control-admin"
                                                        >
                                                            <option value="0">State</option>
                                                            {this.state.usStates.map(st => <option key={st}>{st}</option>)}
                                                        </Field>
                                                        {errors.StateAbbreviation && touched.StateAbbreviation ? (
                                                            <div className="error">{errors.StateAbbreviation}</div>
                                                        ) : null}
                                                    </FormGroup>
                                                </Col>
                                                <Col md={4}>
                                                    <FormGroup>
                                                        <Field name="Zip"
                                                            placeholder="Zip"
                                                            className="form-control form-control-admin"
                                                        />
                                                        {errors.Zip && touched.Zip ? (
                                                            <div className="error">{errors.Zip}</div>
                                                        ) : null}
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <Row className="form-group m-1">
                                                <Col md={2} className="d-flex align-items-center justify-content-sm-start justify-content-md-end">
                                                    <Label htmlFor="Phone1">Phone 1:</Label>
                                                </Col>
                                                <Col md={6}>
                                                    <Field
                                                        name="Phone1"
                                                    >
                                                        {({ field }) => (
                                                            <MaskedInput
                                                                {...field}
                                                                value={this.state.client.Phone1}
                                                                mask={this.state.phoneNumberMask}
                                                                id="Phone1"
                                                                placeholder="Phone 1"
                                                                type="text"
                                                                className={
                                                                    errors.Phone1 && touched.Phone1
                                                                        ? "form-control  form-control-admin error"
                                                                        : "form-control  form-control-admin"
                                                                }
                                                            />
                                                        )}
                                                    </Field>
                                                    {errors.Phone1 && touched.Phone1 && (
                                                        <div className="error">{errors.Phone1}</div>
                                                    )}
                                                </Col>
                                                <Col md={4}>
                                                    <FormGroup>
                                                        <Field as="select"
                                                            name="Phone1TypeID"
                                                            id="Phone1TypeID"
                                                            className="form-control form-control-admin"
                                                        >
                                                            <option value="0">--Phone type--</option>
                                                            {this.state.phoneTypes.map(type => <option key={type.PhoneTypeID} value={type.PhoneTypeID}>{type.PhoneType}</option>)}
                                                        </Field>
                                                        {errors.Phone1TypeID && touched.Phone1TypeID ? (
                                                            <div className="error">{errors.Phone1TypeID}</div>
                                                        ) : null}
                                                    </FormGroup>
                                                </Col>
                                            </Row>

                                            <Row className="form-group m-1">
                                                <Col md={2} className="d-flex align-items-center justify-content-sm-start justify-content-md-end">
                                                    <Label htmlFor="Phone2">Phone 2:</Label>
                                                </Col>
                                                <Col md={6}>
                                                    <Field
                                                        name="Phone2"
                                                    >
                                                        {({ field }) => (
                                                            <MaskedInput
                                                                {...field}
                                                                mask={this.state.phoneNumberMask}
                                                                id="Phone2"
                                                                placeholder="Phone 2"
                                                                type="text"
                                                                className={
                                                                    errors.Phone2 && touched.Phone2
                                                                        ? "form-control  form-control-admin error"
                                                                        : "form-control  form-control-admin"
                                                                }
                                                            />
                                                        )}
                                                    </Field>
                                                    {errors.Phone2 && touched.Phone2 && (
                                                        <div className="error">{errors.Phone2}</div>
                                                    )}
                                                </Col>
                                                <Col md={4}>
                                                    <FormGroup>
                                                        <Field as="select"
                                                            name="Phone2TypeID"
                                                            id="Phone2TypeID"
                                                            className="form-control form-control-admin"
                                                        >
                                                            <option value="0">--Phone type--</option>
                                                            {this.state.phoneTypes.map(type => <option key={type.PhoneTypeID} value={type.PhoneTypeID}>{type.PhoneType}</option>)}
                                                        </Field>
                                                        {errors.Phone2TypeID && touched.Phone2TypeID ? (
                                                            <div className="error">{errors.Phone2TypeID}</div>
                                                        ) : null}
                                                    </FormGroup>
                                                </Col>
                                            </Row>

                                            <Row className="form-group m-1">
                                                <Col md={2} className="d-flex align-items-center justify-content-sm-start justify-content-md-end">
                                                    <Label htmlFor="Email 1">Email 1:</Label>
                                                </Col>
                                                <Col md={8}>
                                                    <Field name="Email1"
                                                        type="Email1"
                                                        placeholder="Email 1"
                                                        className="form-control form-control-admin"
                                                    />
                                                    {errors.Email1 && touched.Email1 ? <div className="error">{errors.Email1}</div> : null}
                                                </Col>
                                            </Row>

                                            <Row className="form-group m-1">
                                                <Col md={2} className="d-flex align-items-center justify-content-sm-start justify-content-md-end">
                                                    <Label htmlFor="Email 2">Email 2:</Label>
                                                </Col>
                                                <Col md={8}>
                                                    <Field name="Email2"
                                                        type="Email2"
                                                        placeholder="Email 2"
                                                        className="form-control form-control-admin"
                                                    />
                                                    {errors.Email2 && touched.Email2 ? <div className="error">{errors.Email2}</div> : null}
                                                </Col>
                                            </Row>

                                            <Row className="form-group mt-3">
                                                <Col xs={8} className="justify-content-end">
                                                    <Button type="submit" className="btn btn-primary bg-primary text-white float-end">
                                                        Save client
                                                    </Button>
                                                </Col>
                                            </Row>
                                        </Form>
                                    )}
                                </Formik>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
};

export default AdminClientDetail;