import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";
import { Button } from 'reactstrap';

function Admin(props) {

    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [phone, setPhone] = useState('');
    const [email, setEmail] = useState('');
    const navigate = useNavigate();

    const handleClientChange = control => (event) => {

        switch (control) {
            case "firstName":
                setFirstName(event.target.value);
                break;
            case "lastName":
                setLastName(event.target.value);
                break;
            case "phone":
                setPhone(event.target.value);
                break;
            case "email":
                setEmail(event.target.value);
                break;
            default:
                break;
        }

    };

    const handleClientsClick = () => {
        navigate(`/admin/clients?firstname=${firstName}&lastname=${lastName}&phone=${phone}&email=${email}`);
    };

    return (
        <div>
            <div className="section">
                <div className="container">
                    <div className="row">
                        <div className="col-12 border border-2 rounded-3 text-center p-2" >
                            <div className="row">
                                <div className="col-12 mb-4">
                                    <h3>Clients</h3>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12 col-md-10 mb-1">
                                    <div className="row">
                                        <div className="col-12 col-md-3 mb-1">
                                            <div className="row">
                                                <div className="col-5 d-flex align-items-center justify-content-end">
                                                    <label htmlFor="inputFirstName" className="ps-3 fw-bold mb-1">First name:</label><br className="d-none d-md-inline" />
                                                </div>
                                                <div className="col-7">
                                                    <input type="text" id="inputFirstName" name="inputFirstName" onChange={handleClientChange("firstName")} value={firstName} className="form-control form-control-admin"></input>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-3 mb-1">
                                            <div className="row">
                                                <div className="col-5 d-flex align-items-center justify-content-end">
                                                    <label htmlFor="inputLastName" className="ps-3 fw-bold mb-1">Last name:</label><br className="d-none d-md-inline" />
                                                </div>
                                                <div className="col-7">
                                                <input type="text" id="inputLastName" name="inputLastName" onChange={handleClientChange("lastName")} value={lastName} className="form-control form-control-admin"></input>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-3 mb-1">
                                            <div className="row">
                                                <div className="col-5 d-flex align-items-center justify-content-end">
                                                    <label htmlFor="inputPhone" className="ps-3 fw-bold mb-1">Phone:</label><br className="d-none d-md-inline" />
                                                </div>
                                                <div className="col-7">
                                                <input type="text" id="inputPhone" name="inputPhone" onChange={handleClientChange("phone")} value={phone} className="form-control form-control-admin"></input>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-3 mb-1">
                                            <div className="row">
                                                <div className="col-5 d-flex align-items-center justify-content-end">
                                                    <label htmlFor="inputEmail" className="ps-3 fw-bold mb-1">Email:</label><br className="d-none d-md-inline" />
                                                </div>
                                                <div className="col-7">
                                                <input type="text" id="inputEmail" name="inputEmail" onChange={handleClientChange("email")} value={email} className="form-control form-control-admin"></input>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-md-2 d-flex align-items-center justify-content-center mb-1">
                                    <Button onClick={handleClientsClick} className="btn btn-primary bg-primary text-white">
                                        Search
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row mt-5">
                        <div className="col-12 border border-2 rounded-3 text-center p-1" >
                            <h3>Appointments</h3>
                            <div className="row">
                            </div>
                        </div>
                    </div>
                    <div className="row mt-5">
                        <div className="col-12 border border-2 rounded-3 text-center p-1" >
                            <h3>Schedules</h3>
                            <div className="row">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
}

export default Admin;